import React, { useContext, useEffect, useRef, useState } from "react";
import Header from "../../Components/Header/Header";
import { PaymentDataa } from "../../Constant/DummyData";
import { Colors } from "../../Constant/Colors";
import "./Payment.css";
import { Icons } from "../../Constant/Icons";
import Buttton from "../../Components/Button/Buttton";
import { useLocation, useNavigate } from "react-router-dom";
import HelthCardPop from "../HelthCardPop/HelthCardPop";
import { HealthCardApi, PymentPost } from "../../Constant/Apiconstant";
import Fetchdata from "../../Constant/Fetchdata";
import {
  PATIENTCARDLISTAPI,
  Pymentbookappointment,
  OntarioPatienttApi,
} from "../../Constant/Apiconstant";
import { SimpleLoader } from "../../Constant/Loader";
import { GlobalContext } from "../../Context/GlabalContext";
// import card from "../../Assets/Images/cardpoint.png";
import { Headers } from "../../Constant/Fetchdata";
import axios from "../../Constant/ApiUrl";
import moment from "moment";
// import momentTZ from "moment-timezone";
import { KEY_USER_DATA, NUMBER } from "../../Redux/Appconstant";
import { store } from "../../Redux/store";
import Loader from "../../Constant/Loader";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { setSessionField } from "../../Redux/SessionAction";

const Payment = () => {
  const session = useSelector((state) => state.session);
  const num = session[NUMBER];
  // console.log(num);
  const location = useLocation();
  const selectedsymtom = location.state?.selectedsymtom;
  const slottype = location.state?.slotetype;
  const choesdata = location.state?.selectedSession;
  const seledDate = location.state?.selectedDate;
  const seleTimes = location.state?.selectedTimes;
  const paymenturl = location.state?.payurl;
  const {
    FindMDFemaliy,
    setFindMDFemaliy,
    // precriptionselecteddata,
    // setprecriptionselecteddata,
    Consultation,
  } = useContext(GlobalContext);

  // console.log(seleTimes[0], "seleTimes");

  let arrayOfObjects = seleTimes?.map((time) => ({ time: time }));
  const getObjectTime = arrayOfObjects?.length ? arrayOfObjects[0]?.time : null;
  // console.log(getObjectTime, "getObjectTime");

  // const maindataIddd = location.state?.maindata;
  // console.log(maindataIddd, "maindataIddd");
  const feeData = location?.state?.response;
  // const number = location?.state?.Numbar;
  // const Anynum = location?.state?.num;
  // console.log(Anynum, "Anynum");
  const OkayDataApi = location?.state?.OkayData?.isSelected?.data;

  const currencyAmmunt = feeData?.data?.consultation_fee;
  // console.log(feeData, "currencyAmmunt");
  const [selectedcard, setselectedcard] = useState({
    cardid: null,
    isSelected: null,
  });

  // console.log(selectedcard, "selectedcard");
  // console.log(Consultation, "Consultation");
  const [selected, setselected] = useState(0);
  // console.log(selected, "selected");
  const [Ontario, setOntario] = useState(false);
  // console.log(Ontario, "Ontario");
  const [isSelected, setIsSelected] = useState({ data: null, index: null });
  const [showmodel, setshowmodel] = useState(false);
  const [loading, setloading] = useState(false);

  // console.log(userDATA, "userDATA");
  const navigate = useNavigate();
  const selectedProfileId = JSON.parse(
    localStorage.getItem("selectedProfileId")
  );

  // console.log(selectedProfileId, "selectedProfileId");
  const { response } = Fetchdata(HealthCardApi, {}, "GET");
  // console.log(response, "response");
  const handlenavigate = (num) => {
    if (num === 2) {
      navigate("/Addcard");
    } else if (num === 1) {
      navigate("/Addhealthcard");
    } else if (num === 3) {
      navigate("/HealthInsurance");
    }
  };

  // const handleCardClick = (itm, index) => {
  //   setIsSelected({ data: itm, index: index });
  //   // console.log(itm, "v");
  // };

  // const { response: ok, isloading } = Fetchdata(PATIENTCARDLISTAPI);
  // console.log(ok, "ok");
  // useEffect(() => {
  //   setprecriptionselecteddata((prev) => ({
  //     ...prev,
  //     cardID: selectedcard.cardid,
  //   }));
  // }, [selectedcard]);

  let dataAppo = moment(seledDate).format("YYYY-MM-DD");
  let timeAppo = getObjectTime;
  let dateString = `${dataAppo} ${timeAppo}`;

  let dateFormat = "YYYY-MM-DD h:mm A";

  let dateObject = moment(dateString, dateFormat);

  let timestampMilliseconds = dateObject.valueOf();

  // console.log(timestampMilliseconds);

  const header = Headers();

  const onSubmit = async () => {
    setloading(true);
    try {
      const res = await axios.post(
        PymentPost,
        {
          date: Date.now(),
          hospital_id: "",
          familyMemberId:
            selectedProfileId?.relationship === "self"
              ? ""
              : selectedProfileId?._id,
          reasonAppointment: Consultation,
          waitingRoom: true,
          stripe_card_id: selectedcard?.cardid,
          symptoms: selectedsymtom,
          choose_payment_method:
            selected == 0 ? "health_card" : "private_payment",
          healthCard_id: "",
        },
        header
      );
      // console.log(res);
      if (res.status === 200 && res.data.status === "success") {
        toast.success(res.data.message);
        navigate("/Pymentsucssas");
        store.dispatch(setSessionField(NUMBER, undefined));
      } else {
        toast.error(res.data.message);
      }
      setloading(false);
      // console.log(res, "Submit res........");
    } catch (error) {
      // console.log(error);
      setloading(false);
    }
  };

  // console.log(selectedcard.cardid);

  const Apicall = async () => {
    setloading(true);
    const selectedProfileId = localStorage.getItem("selectedProfileId");
    try {
      const res = await axios.post(
        Pymentbookappointment,
        {
          hospital_id: "",
          date: moment(seledDate).format("YYYY-MM-DD").toString(),
          time_slot: getObjectTime,
          doctor_id: OkayDataApi?._id || FindMDFemaliy,
          appointmentType: slottype,
          reasonAppointment: Consultation,
          familyMemberId:
            selectedProfileId?.relationship === "self"
              ? ""
              : selectedProfileId?._id,
          symptoms: OkayDataApi?.selectedsymtom,
          session: choesdata,
          appointment_duration: "15 minutes",
          choose_payment_method:
            selected === 0 ? "health_card" : "private_payment",
          healthCard_id: "",
          stripe_card_id: selectedcard?.cardid,
          consultation_fee: currencyAmmunt?.amount,
          payment_currency: currencyAmmunt?.currency,
          insurance_id: "",
          utc_time: timestampMilliseconds.toString(),
        },
        header
      );
      setloading(false);
      if (
        res.status === 200 &&
        res.data.message === "Appointment Booked successfully"
      ) {
        store.dispatch(setSessionField(NUMBER, undefined));
        toast.success(res.data.message);
        navigate("/Pymentsucssas");
        setFindMDFemaliy("");
        setselectedcard("");
      } else {
        toast.error(res.data.message);
        // console.log("elsepart");
      }
      // console.log(res, "Apicall res.........");
    } catch (error) {
      // console.log(error);
      setloading(false);
    }
  };

  const func = (e) => {
    e.preventDefault();
    if (num === 1) {
      // console.log("num 1");
      onSubmit();
    } else {
      // console.log("num 2");
      Apicall();
    }
  };

  const { response: GetData } = Fetchdata(
    OntarioPatienttApi,
    { header },
    "GET"
  );
  useEffect(() => {
    if (GetData?.data) {
      setOntario(GetData.data.isOntario);
    }
  }, [GetData]);

  const iframref = useRef();

  const handleIframeLoad = () => {
    // Check the iframe's src for success callback (if URL params are used)
    const iframe = document.querySelector(".mainpaymentiframe");
    const iframeUrl = iframe?.contentWindow?.location?.href;
    console.log(iframeUrl);
    if (iframeUrl?.includes("payment_status=success")) {
      const transactionId = new URLSearchParams(iframeUrl.split("?")[1]).get(
        "transaction_id"
      );
      console.log("Payment successful!", transactionId);
      // Handle payment success (e.g., update state, make API call)
    }
  };

  return (
    <>
      {/* <Header text={"Choose booking method"} onClick={() => navigate(-1)} /> */}
      <iframe
        ref={iframref}
        className="mainpaymentiframe"
        src={paymenturl?.paymentUrl}
        frameborder="0"
        onLoad={handleIframeLoad}
      ></iframe>
      {/* <form
        onSubmit={func}
        className="mt-3 w-100 h-100 px-3 d-flex flex-column align-items-center g-3"
      >
        <div className="paymenttype">
          {PaymentDataa.map((item, index) => {
            if (index !== 0 || !(num !== 1 && !Ontario)) {
              return (
                <div
                  style={{
                    backgroundColor: selected === index ? Colors.blue : null,
                  }}
                  onClick={() => {
                    setselected(index);
                    setselectedcard({
                      isSelected: null,
                      cardid: null,
                    });
                  }}
                  key={item.id}
                >
                  <div className="mb-3">{item.icon}</div>
                  <h5
                    style={{
                      color: selected === index ? "#ffffff" : null,
                      textAlign: "center",
                    }}
                  >
                    {item.name}
                  </h5>
                </div>
              );
            }
          })}
        </div>
        <div className="feee mt-3">
          <div>
            <span>Appointment fee</span>
          </div>
          <p>
            <b>
              {currencyAmmunt?.currency || "$"} {currencyAmmunt?.amount || "20"}
            </b>
            /Session
          </p>
        </div>
        {Ontario === true || num === 1 ? (
          <>
            {selected === 0 && (
              <div className="response-main-data">
                <div>
                  <h2>Health card</h2>
                </div>
                <div className="Method-Health-card">
                  {isloading ? (
                    <SimpleLoader style={{ height: "300px" }} />
                  ) : (
                    <>
                      {!response?.data ? (
                        <h1 className="text-center w-100">
                          You have no health card
                        </h1>
                      ) : (
                        <>
                          {response?.data?.map((itm, index) => {
                            return (
                              <>
                                <div>
                                  <div
                                    className={`FindMD-img-contain ${
                                      isSelected.index == index
                                        ? "selected"
                                        : ""
                                    }`}
                                    onClick={() => handleCardClick(itm, index)}
                                  >
                                    <div>
                                      <img
                                        className="Cardimg-docter"
                                        src={itm?.image}
                                        alt=""
                                      />
                                    </div>
                                    <div className="Dorothy-Lucas-sapn-div">
                                      <div className="Lucas-awy-main-div">
                                        <div className="Dorothy-extra-div">
                                          <span className="Dorothy-sapn">
                                            {itm?.cardHolderName}
                                          </span>
                                        </div>
                                      </div>

                                      <div className="">
                                        <span>Card Number</span>
                                        <span className="Am-pm-span">
                                          {" "}
                                          : {itm?.healthCardNumber}
                                        </span>
                                      </div>
                                      <div className="">
                                        <span>Version Code</span>
                                        <span className="Am-pm-span">
                                          {" "}
                                          : {itm?.versionCode}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
          </>
        ) : (
          ""
        )}
        {selected === 1 && (
          <>
            <div className="w-100">
              <h2>Private payment method</h2>
            </div>
            <div className="w-100  mt-3 d-flex flex-column align-items-center g-3">
              {isloading ? (
                <SimpleLoader style={{ height: "300px" }} />
              ) : (
                <div className="pymaint-div-thard">
                  {ok?.data?.map((item, index) => {
                    return (
                      <div
                        className="payment-input67"
                        onClick={() =>
                          setselectedcard({
                            isSelected: index,
                            cardid: item.id,
                          })
                        }
                        key={item.id}
                      >
                        <span className="card-span-divv">
                          XXXX XXXX XXXX{item.last4}
                        </span>
                        <div className="dustbin-logo-black">
                          <span>
                            {selectedcard.isSelected === index
                              ? Icons.cardselecticon
                              : null}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </>
        )}
        <Buttton
          onClick={() => setshowmodel(true)}
          type={"button"}
          style={{ backgroundColor: Colors.blue, marginTop: "1rem" }}
        >
          Add new card
        </Buttton>
        <Buttton
          disable={
            selected === 0 && response?.data === undefined ? true : false
          }
          type={"submit"}
          style={{ backgroundColor: Colors.blue, marginTop: "1rem" }}
        >
          Submit
        </Buttton>
      </form> */}
      {/* {showmodel && (
        <HelthCardPop
          btn1={"Add New Health Card"}
          btn2={"Add New Credit Card"}
          btn3={"Add Insurance"}
          handleClick={handlenavigate}
          showmodel={showmodel}
          setshowmodel={setshowmodel}
        />
      )} */}
      {/* {loading && <Loader />} */}
    </>
  );
};

export default Payment;
