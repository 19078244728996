import { Button, Dialog, DialogActions } from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { store } from "../../Redux/store";
import { KEY_USER_DATA, NUMBER } from "../../Redux/Appconstant";
import { PaystackButton } from "react-paystack";
import { toast } from "react-toastify";
import axios from "../../Constant/ApiUrl";
import { PymentPost } from "../../Constant/Apiconstant";
import { GlobalContext } from "../../Context/GlabalContext";
import Loader from "../../Constant/Loader";
import { setSessionField } from "../../Redux/SessionAction";
import { Headers } from "../../Constant/Fetchdata";

const AddModel = ({
  showmodel,
  setshowmodel,
  btn1,
  handleClick,
  btn2,
  btn3,
  getpricewaitingroom,
}) => {
  // const navigate = useNavigate();
  const Mydata = store.getState().session[KEY_USER_DATA];
  const { Consultation, selectedsymtom } = useContext(GlobalContext);
  const [loading, setloading] = useState(false);
  // console.log(Mydata);

  const navigate = useNavigate();
  const header = Headers();
  const onSuccess = async (ref) => {
    // console.log(ref);
    setloading(true);
    const selectedProfileId = localStorage.getItem("selectedProfileId");
    setloading(true);
    try {
      const res = await axios.post(
        PymentPost,
        {
          date: Date.now(),
          familyMemberId:
            selectedProfileId?.relationship === "self"
              ? ""
              : selectedProfileId?._id,
          reasonAppointment: Consultation,
          waitingRoom: true,
          symptoms: selectedsymtom,
          choose_payment_method: "private_payment",
          reference: ref.reference,
        },
        header
      );
      // console.log(res);
      if (res.status === 200 && res.data.status === "success") {
        toast.success(res.data.message);
        navigate("/Pymentsuccess");
        store.dispatch(setSessionField(NUMBER, undefined));
      } else {
        toast.error(res.data.message);
      }
      setloading(false);
      // console.log(res, "Submit res........");
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };
  const onClose = (ref) => {
    // console.log(ref);
  };

  // console.log(getpricewaitingroom);

  return (
    <div>
      <Dialog open={showmodel}>
        <DialogActions>
          <div className="addcardmodel">
            {/* <Button onClick={() => handleClick(1)} color="primary" autoFocus>
              {btn1}
            </Button> */}
            <PaystackButton
              // disabled={isloading || selectedTimes.length < 1 ? true : false}
              email={Mydata?.basicInfo?.email}
              currency={getpricewaitingroom?.currency}
              amount={+getpricewaitingroom?.price * 100}
              publicKey={process.env.REACT_APP_PAY_KEY}
              text="Any Available Doctor"
              onSuccess={onSuccess}
              onClose={onClose}
              className="paybtn"
            />
            <Button onClick={() => handleClick(2)} color="primary" autoFocus>
              {btn2}
            </Button>
            {Mydata?.hospital_id && (
              <Button onClick={() => handleClick(3)} color="primary" autoFocus>
                {btn3}
              </Button>
            )}
            <Button
              onClick={() => setshowmodel(false)}
              color="primary"
              autoFocus
            >
              Cancel
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      {loading && <Loader />}
    </div>
  );
};

export default AddModel;
