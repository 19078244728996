import { useContext, useEffect, useState } from "react";
import apiurl from "../Constant/ApiUrl";
import axios from "axios";
import { store } from "../Redux/store";
import { KEY_USER_DATA } from "../Redux/Appconstant";
import { GlobalContext } from "../Context/GlabalContext";

const Fetchdata = (url, params, method) => {
  const { settokenexpire } = useContext(GlobalContext);
  const token = store.getState().session[KEY_USER_DATA]?.token || "";
  // const token = store.getState().session[KEY_USER_DATA]?.token || "";
  const [isloading, setisloading] = useState(false);
  const [error, seterror] = useState(false);
  const [response, setresponse] = useState();
  // const Navigate = useNavigate();
  const getdata = async () => {
    setisloading(true);
    try {
      const res = await apiurl({
        method,
        url,
        data: params,
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      });
      setresponse(res.data);
      setisloading(false);
      // console.log(res);
    } catch (error) {
      if (
        error?.response?.status === 401 &&
        error?.response?.data?.message == "Unauthorized please login"
      ) {
        settokenexpire(true);
      }
      seterror(error);
      console.log(error);
      setisloading(false);
    } finally {
      setisloading(false);
    }
  };

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();
    getdata();
    return () => {
      cancelTokenSource.cancel("Request canceled on component unmount");
    };
  }, []);

  return { error, response, isloading, getdata };
};

export default Fetchdata;

export const Contenttype = {
  row: "application/json",
  formdata: "multipart/form-data",
};

export const Headers = (Contenttype = "application/json") => {
  const token = store.getState().session[KEY_USER_DATA]?.token;
  // console.log(token);
  return {
    headers: {
      "Content-Type": Contenttype,
      Authorization: `${token}`,
    },
  };
};
