import React, { Fragment, useContext, useState } from "react";
import "./PrescriptionDetail.css";
import Header from "../../Components/Header/Header";
import { useNavigate } from "react-router-dom";
import Fetchdata, { Headers } from "../../Constant/Fetchdata";
import { PRESCRIPTIONLIST, renewalSlot } from "../../Constant/Apiconstant";
import Loader, { SimpleLoader } from "../../Constant/Loader";
import Buttton from "../../Components/Button/Buttton";
import { Colors } from "../../Constant/Colors";
import { GlobalContext } from "../../Context/GlabalContext";
import { monthNames } from "../../Constant/DummyData";
import { PaystackButton } from "react-paystack";
import { store } from "../../Redux/store";
import { KEY_USER_DATA } from "../../Redux/Appconstant";
import axios from "../../Constant/ApiUrl";
import { toast } from "react-toastify";
import moment from "moment";

const PrescriptionDetail = () => {
  const navigate = useNavigate();
  const [show, setshow] = useState(false);
  const [selected, setselected] = useState(0);
  const [precriptiondata, setprecriptiondata] = useState([]);
  const Mydata = store.getState().session[KEY_USER_DATA];

  const [Idss, setIdss] = useState([]);
  // console.log(Idss, "Idss");
  const { response, isloading } = Fetchdata(PRESCRIPTIONLIST, {}, "GET");
  const [loading, setloading] = useState(false);
  const { precriptionselecteddata, setprecriptionselecteddata } =
    useContext(GlobalContext);
  // console.log(precriptionselecteddata, "precriptionselecteddata");
  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    let hour = date.getHours();
    const minute = date.getMinutes();
    const ampm = hour >= 12 ? "PM" : "AM";
    hour = hour % 12 || 12;
    const formattedDate = `${month} ${day}, ${hour}:${
      minute < 10 ? "0" : ""
    }${minute} ${ampm}`;

    return formattedDate;
  }

  // console.log(precriptiondata);
  const handlenavigate = () => {
    if (show) {
      setshow(false);
    } else {
      navigate(-1);
    }
  };

  const handleclick = (item) => {
    // console.log(item)
    setprecriptionselecteddata((prev) => {
      const currentPrescriptionIds =
        prev && prev.prescriptionIds ? prev.prescriptionIds : [];
      const newPrescriptionIds = currentPrescriptionIds.includes(item._id)
        ? currentPrescriptionIds.filter((id) => id !== item._id)
        : [...currentPrescriptionIds, item._id];

      setIdss(newPrescriptionIds);
      return {
        ...prev,
        prescriptionIds: newPrescriptionIds,
      };
    });
  };

  const header = Headers();
  const onSuccess = async (ref) => {
    // console.log(ref);
    setloading(true);
    try {
      const res = await axios.post(
        renewalSlot,
        {
          date: moment(new Date()).format("YYYY-MM-DD").toString(),
          doctorID: precriptionselecteddata?.doctor_id,
          reference: ref.reference,
        },
        header
      );
      // console.log(res);
      if (res.status === 200 && res.data.status === "success") {
        toast.success("Prescription request sent to doctor");
        navigate("/Home");
      } else {
        toast.error(res.data.message);
      }
      setloading(false);
      // console.log(res, "Submit res........");
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };
  const onClose = (ref) => {
    // console.log(ref);
  };

  return (
    <>
      <Header onClick={handlenavigate} text={"Prescription detail"} />
      <section className="PrescriptionDetail-main-section px-3">
        {show ? (
          <div className="mainrpecriptiondetail">
            {precriptiondata?.map((item, index) => {
              // console.log(precriptiondata, "precriptiondata");
              const formattedResult = formatTimestamp(item.prescriptionDate);
              return (
                <div className="d-flex flex-column mb-3 w-100 " key={index}>
                  <h5>{formattedResult}</h5>
                  <div className="d-flex flex-column gap-2">
                    {item?.prescriptionData?.map((_item) => {
                      return (
                        <div key={_item._id}>
                          <label
                            htmlFor="check"
                            className="d-flex align-items-center gap-2"
                          >
                            <input
                              type="checkbox"
                              // id="check"
                              onClick={() => handleclick(_item)}
                              className="checkboxpre"
                            />
                            <span
                              className="Lorem-main-span"
                              id="check"
                            >{`${_item.nameOfMedicine} ${_item.duration} ${_item.doseUnit} ${_item.frequency} x ${_item.duration} ${_item.durationUnit}`}</span>
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
            {/* <Buttton
              disable={
                precriptionselecteddata?.prescriptionIds?.length === 0
                  ? true
                  : false
              }
              // onClick={() => navigate("/PaymentMethod")}
              style={{
                backgroundColor: Colors.blue,
              }}
            >
              Renew
            </Buttton> */}
            <PaystackButton
              email={Mydata?.basicInfo?.email}
              currency={precriptionselecteddata?.renewalPrescriptionCurrency}
              amount={+precriptionselecteddata?.renewalPrescriptionAmount * 100}
              publicKey={process.env.REACT_APP_PAY_KEY}
              text="Renew"
              onSuccess={onSuccess}
              onClose={onClose}
              className="paybtn"
            />
          </div>
        ) : (
          <div className="PrescriptionDetail-main-div">
            {isloading ? (
              <SimpleLoader style={{ height: "300px" }} />
            ) : (
              <>
                {!response?.data ? (
                  <h1>{response?.message}</h1>
                ) : (
                  response?.data?.map((itm, index) => {
                    {
                      /* console.log(itm, "iddss"); */
                    }
                    const formattedResult = formatTimestamp(itm.utc_time);
                    return (
                      <Fragment key={index}>
                        <label
                          className="PrescriptionDetail-thard"
                          onClick={() => {
                            setshow(true);
                            setselected(index);
                            setprecriptiondata(itm.prescriptions);
                            setprecriptionselecteddata({
                              appointmentID: itm.appointmentId,
                              doctor_id: itm.doctor_id,
                              prescriptionIds: Idss,
                              renewalPrescriptionAmount:
                                itm.renewalPrescriptionAmount,
                              renewalPrescriptionCurrency:
                                itm.renewalPrescriptionCurrency,
                            });
                          }}
                        >
                          <input
                            type="radio"
                            name="appointment"
                            // checked={selected}
                            defaultChecked={selected}
                          />
                          <span className="Lorem-main-span">{`Appointment with ${itm.doctorName} on ${formattedResult}`}</span>
                        </label>
                        <div className="PrescriptionDetail-main-line mt-4"></div>
                      </Fragment>
                    );
                  })
                )}
              </>
            )}
          </div>
        )}
      </section>
      {loading && <Loader />}
    </>
  );
};

export default PrescriptionDetail;
