import React, { useState, useContext } from "react";
import "./YourProfile.css";
import { useFormik } from "formik";
import { YourProfileshemas } from "../../Schemas/index";
import profilePic from "../../Assets/Images/default_pic_ic@3x.png";
import SandIcon from "../../Assets/Images/camera_ic_yellow@3x.png";
import Blue from "../../Assets/Images/blue_bg@3x.png";
import { GlobalContext } from "../../Context/GlabalContext";
import OtherInformation from "../OtherInformation/OtherInformation";
import HealthDataInformation from "../HealthDataInformation/HealthDataInformation";
import { useNavigate } from "react-router-dom";
import Input from "../../Components/Input/Input";
import { Icons } from "../../Constant/Icons";
import { toast } from "react-toastify";

const YourProfile = () => {
  const [Image, setImage] = useState(null);
  const { steps, setsteps, setregiaterdata } = useContext(GlobalContext);
  // const [startDate, setStartDate] = useState(new Date());

  const initialValues = {
    FullName: "",
    LastName: "",
    date: "",
    image: "",
  };
  const calculateage = (dob) => {
    let today = new Date();
    let birthdatobj = new Date(dob);
    let age = today.getFullYear() - birthdatobj.getFullYear();
    const month = today.getMonth() - birthdatobj.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthdatobj.getDate())) {
      age--;
    }
    return age;
  };
  const onSubmit = () => {
    let age = calculateage(values.date);
    if (age < 18) {
      toast.error("Age must be 18 +");
    } else if (age > 65) {
      toast.error("Age must be less then 65");
    } else {
      setregiaterdata({
        fullname: values.FullName,
        lastName: values.LastName,
        image: Image,
        date: values.date,
      });
      setsteps(2);
    }
    // console.log(age, "age");
  };

  const {
    values,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: YourProfileshemas,
    onSubmit,
  });

  // console.log(steps);
  const navigate = useNavigate();
  return (
    <>
      <div className="All-Conatinor-perfect-divv">
        {steps == 1 ? (
          <form
            onSubmit={handleSubmit}
            className="All-Containor-perfect-second-divv"
          >
            <div className="Profile-extra-div">
              <div className="Profile-main-Your">
                <span
                  style={{ cursor: "pointer", width: "max-content" }}
                  onClick={() => navigate(-1)}
                >
                  {Icons.backarrowblack}
                </span>
                <h6 className="mt-2">Step {steps} to 3</h6>
                <span className="Order-history-span">Your profile</span>
                <span className="Upload-profile">
                  Upload your profile to get better consultants from doctor.
                </span>
              </div>
            </div>
            <div className="second-div mt-4">
              <div className="all-input-div-main">
                <div className="Ht-ml-for-imput">
                  <label className="" htmlFor="mal">
                    <img src={profilePic} alt="" className="pic-on-add" />
                    <div className="SandIcon-addeting-div">
                      <img className="Cemara-te-img" src={SandIcon} alt="" />
                    </div>
                  </label>
                  <input
                    id="mal"
                    type="file"
                    onChange={(e) => {
                      setImage(e.target.files[0]);
                      setFieldValue(
                        "image",
                        URL.createObjectURL(e.target.files[0])
                      );
                    }}
                    style={{ display: "none" }}
                    className="Malte-pal-cls"
                  />
                  <img
                    src={Image ? URL.createObjectURL(Image) : null}
                    alt=""
                    width="100px"
                    height="100px"
                    className="add-kiya-muja"
                  />
                </div>
                <br />
                {errors.image && touched.image ? (
                  <p className="mastu-to-eroor mt-2 ">{errors.image} </p>
                ) : null}
                <div className="mt-5 all-input-areya">
                  <div className="full-and-last-name">
                    <div className="Full-all-Name-input">
                      <span className="">Full name</span>
                      <div className="full-name-bug-div">
                        <div className="text-boxx-fullname mt-2">
                          <span>{Icons.user}</span>
                          <input
                            className="i-t-a-host-itm"
                            type="text"
                            placeholder="Full name"
                            name="FullName"
                            value={values.FullName}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      {errors.FullName && touched.FullName ? (
                        <p className="mastu-to-eroor mt-2">
                          {errors.FullName}{" "}
                        </p>
                      ) : null}
                    </div>
                    <div>
                      <span className="">Last name</span>
                      <div className="full-name-bug-div">
                        <div className="text-boxx-fullname mt-2">
                          <span>{Icons.user}</span>
                          <input
                            className="i-t-a-host-itm"
                            type="text"
                            placeholder="Last name"
                            name="LastName"
                            value={values.LastName}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      {errors.LastName && touched.LastName ? (
                        <p className="mastu-to-eroor mt-2">
                          {errors.LastName}{" "}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <div className="input-date-main-div mt-4">
                    <span>Age</span>
                    <input
                      placeholder="13 May 1995"
                      className="Date-of-Birth-input"
                      type="date"
                      name="date"
                      max="9999-12-31"
                      value={values.date}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {errors.date && touched.date ? (
                      <p className="mastu-to-eroor">{errors.date} </p>
                    ) : null}
                  </div>
                </div>
                <div className="mt-4 mb-5">
                  <button
                    type="submit"
                    // onClick={onSubmit}
                    className="Btn-Register-karo"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
            <div>
              <img className="YourProfile-next-blue" src={Blue} alt="" />
            </div>
          </form>
        ) : steps == 2 ? (
          <OtherInformation />
        ) : steps == 3 ? (
          <HealthDataInformation />
        ) : null}
      </div>
    </>
  );
};

export default YourProfile;
