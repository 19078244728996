/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Splash from "./Splash/Splash";
import CorporateLoginPopup from "./Patient/CorporateLoginPopup/CorporateLoginPopup";
import Login from "./Auth/Login/Login";
import ForgotPassword from "./Auth/ForgotPassword/ForgotPassword";
import Profilesidebar from "./Patient/Profilesidebar/Profilesidebar";
import YourProfile from "./Auth/YourProfile/YourProfile";
import FindMD from "./Patient/FindMD/FindMD";
import SelectProfile from "./Patient/SelectProfile/SelectProfile";
import Home from "./Home/Home";
import Calender from "./Patient/Calender/Calender";
import BookAnAppointment1 from "./Patient/BookAnAppointment1/BookAnAppointment1";
import Torso from "./Patient/Torso/Torso";
import BookAnAppointment5 from "./Patient/BookAnAppointment5/BookAnAppointment5";
import OtherDiseasesPsychiatry from "./Patient/OtherDiseasesPsychiatry/OtherDiseasesPsychiatry";
import UpcomingAppointment from "./Patient/UpcomingAppointment/UpcomingAppointment";
import UpcomingAppointment2 from "./Patient/UpcomingAppointment2/UpcomingAppointment2";
import HealthData from "./Patient/HealthData/HealthData";
import RescheduleAppointment from "./Patient/RescheduleAppointment/RescheduleAppointment";
import HealthDataDetail from "./Patient/HealthDataDetail/HealthDataDetail";
import Notifications from "./Patient/Notifications/Notifications";

import BookAnAppointment3 from "./Patient/BookAnAppointment3/BookAnAppointment3";

import Settings from "./Patient/Settingss/Settings";
import ChangePassword from "./Auth/ChangePassword/ChangePassword";
import PaymentMethod from "./Patient/PaymentMethod/PaymentMethod";

import Faq from "./Patient/FAQ/Faq";
import WaitingRoom from "./Patient/WaitingRoom/WaitingRoom";
import RateDoctor from "./Patient/RateDoctor/RateDoctor";
import AppointmentBooked from "./Patient/AppointmentBooked/AppointmentBooked";

import ReasonForConsultation from "./Patient/BookAnAppointment3/ReasonForConsultation";
import Other from "./Patient/BookAnAppointment3/Other";
import PrescriptionDetail from "./Patient/PrescriptionDetail/PrescriptionDetail";
import MyCards from "./Patient/MyCards/MyCards";
import SelectPrescription from "./Patient/SelectPrescription/SelectPrescription";
import DoctorProfile from "./Patient/DoctorProfile/DoctorProfile";
import Payment from "./Patient/Payment/Payment";
import DoctorRegister from "./DoctorAuth/DoctorRegister/DoctorRegister";
// import CreateProfileDoctor from "./DoctorAuth/CreateProfileDoctor/CreateProfileDoctor";
// import ProfessionalDoctor from "./DoctorAuth/ProfessionalDoctor/ProfessionalDoctor";
import AddBankAccount from "./DoctorAuth/AddBankAccount/AddBankAccount";

import CompleteAppointment from "./Patient/CompleteAppointment/CompleteAppointment";

import Prescription from "./Doctor/Prescription/Prescription";
import SelectLab from "./Doctor/SelectLab/SelectLab";
import ClinicalNote from "./Doctor/ClinicalNote/ClinicalNote";
import SickNote from "./Doctor/SickNote/SickNote";
import SentLabPopup from "./Doctor/SentLabPopup/SentLabPopup";
import SickNotePopup from "./Doctor/SickNotePopup/SickNotePopup";
import WaitingRoomss from "./Doctor/WaitingRoomss/WaitingRoomss";
import AvailabilityDoctor from "./Doctor/AvailabilityDoctor/AvailabilityDoctor";
import BillingDoctor from "./Doctor/BillingDoctor/BillingDoctor";
import GeneralInfo from "./Doctor/GeneralInfo/GeneralInfo";
import MyProfileProfessionalInfoDoctor from "./Doctor/MyProfileProfessionalInfoDoctor/MyProfileProfessionalInfoDoctor";
import MyProfileLicence from "./Doctor/MyProfileLicence/MyProfileLicence";
import CompleteAppointmentDetail from "./Doctor/CompleteAppointmentDetail/CompleteAppointmentDetail";
import MyComponent from "./Doctor/MyComponent/MyComponent";
import ReferralDoctor from "./Doctor/ReferralDoctor/ReferralDoctor";
import PendingRequestsDoctor from "./Doctor/PendingRequestsDoctor/PendingRequestsDoctor";
import CompletedAppointmentDoctor from "./Doctor/CompletedAppointmentDoctor/CompletedAppointmentDoctor";
import Notes from "./Doctor/Notes/Notes";
import UpcomingAppointmentDoctor from "./Doctor/UpcomingAppointmentDoctor/UpcomingAppointmentDoctor";
import UpcomingAppointmentDetailDoctor from "./Doctor/UpcomingAppointmentDetailDoctor/UpcomingAppointmentDetailDoctor";
import DoctorHome from "./Home/DoctoeHome/DoctorHome";
import DoctorBook from "./Doctor/DoctorBook/DoctorBook";
// import Videocall from "./Videocall/Videocall";
import SessionExpiryPopup from "./Components/SessionExpiryPopup/SessionExpiryPopup";
import { GlobalContext } from "./Context/GlabalContext";
import Fetchdata from "./Constant/Fetchdata";
import { NOTIFICATIONAPICOUNT } from "./Constant/Apiconstant";
// import ChooseBookingMethod from "./Patient/ChooseBookingMethod/ChooseBookingMethod";
import InvestigationDoctor from "./Doctor/InvestigationDoctor/InvestigationDoctor";
import Xrayultrasound from "./Doctor/Xrayultrasound/Xrayultrasound";
import FamilyMember from "./Patient/FamilyMember/FamilyMember";
import Signature from "./Doctor/Signature/Signature";
import Cardiology from "./Doctor/Cardiology/Cardiology";
import Ctscan from "./Doctor/Ctscan/Ctscan";
import GeneralForm from "./Doctor/GeneralForm/GeneralForm";

import OtpInpuT from "./Auth/Otp/Otp";
import HealthcardCreditcard from "./Patient/Healthcard-CreditCard/HealthcardCreditcard";
import Stripewraper from "./Components/Stripewraper/Stripewraper";
import Editprofile from "./Auth/Editprofile/Editprofile";
import AddfamilyMember from "./Patient/FamilyMember/AddfamilyMember";
import OtherForm from "./Doctor/OtherForm/OtherForm";
import GeneralPublicHealthForm from "./Doctor/GeneralPublicHealthForm/GeneralPublicHealthForm";
import HIVForm from "./Doctor/HIVForm/HIVForm";

import AddhealthCard from "./Patient/Healthcard-CreditCard/AddhealthCard";
import ContactUs from "./CMS/ContactUs/ContactUs";
import AboutUs from "./CMS/AboutUs/AboutUs";
import PrivacyPolicy from "./CMS/PrivacyPolicy/PrivacyPolicy";
import TermsCondition from "./CMS/Terms-Conditions/TermsCondition";
import RefillRequest from "./Doctor/RefillRequest/RefillRequest";

import DataSuccessfully from "./Doctor/DataSuccessfully/DataSuccessfully";
import CTscenMRI from "./Doctor/CTscenMRI/CTscenMRI";
import Availablity from "./Doctor/Availablity/Availablity";
import Thisispaid from "./Doctor/Thisispaid/Thisispaid";
import AddDoctorInformation from "./Doctor/AddDoctorInformation/AddDoctorInformation";
import TowAddDoctorInformation from "./Doctor/TowAddDoctorInformation/TowAddDoctorInformation";
import EditProfileDoctor from "./Doctor/EditProfileDoctor/EditProfileDoctor";
import ProfessionalInfo from "./Doctor/ProfessionalInfo/ProfessionalInfo";
import IncompleteNotes from "./Doctor/IncompleteNotes/IncompleteNotes";
import LabNotes from "./Doctor/LabNotes/LabNotes";
import OntarioForm from "./Doctor/OntarioForm/OntarioForm";
import PrescriptionNotes from "./Doctor/PrescriptionNotes/PrescriptionNotes";
import FindMDHead from "./Patient/FindMDHead/FindMDHead";
import BookandAppoinmentSlote from "./Patient/BookandAppoinmentSlote/BookandAppoinmentSlote";
import AppoinmentCalender from "./Patient/AppoinmentCalender/AppoinmentCalender";
import Cardiologest from "./Patient/Cardiologest/Cardiologest";
import CardiologestPop from "./Patient/CardiologestPop/CardiologestPop";
import HelthCardPop from "./Patient/HelthCardPop/HelthCardPop";
import HealthInsurance from "./Patient/HealthInsurance/HealthInsurance";
import Doctorschedule from "./Doctor/Doctorschedule/Doctorschedule";
import SetAvailablity from "./Patient/SetAvailablity/SetAvailablity";
import SelectedPharmacy from "./Doctor/SelectedPharmacy/SelectedPharmacy";
import Sendtoleb from "./Doctor/Sendtoleb/Sendtoleb";
import DoctorWaitingroom from "./Doctor/DoctorWaitingroom/DoctorWaitingroom";
import Pymentsucssas from "./Patient/Pymentsucssas/Pymentsucssas";
import PendingInsurance from "./Patient/PendingInsurance/PendingInsurance";
import PatientVidoCall from "./Patient/PatientVidoCall/PatientVidoCall";
import SickCompleteAppointment from "./Patient/SickCompleteAppointment/SickCompleteAppointment";
import SicNotepay from "./Patient/SicNotepay/SicNotepay";
import SickNotePayment from "./Patient/SickNotePayment/SickNotePayment";
import RatingCompletedAppointment from "./Doctor/RatingCompletedAppointment/RatingCompletedAppointment";
import ConsultationHistory from "./Doctor/ConsultationHistory/ConsultationHistory";
import UpdetProfile from "./Auth/YourProfile/UpdetProfile/UpdetProfile";
import UpdetHealthDataDetail from "./Patient/UpdetHealthDataDetail/UpdetHealthDataDetail";
import UpdetHealthDataDetailInformation from "./Patient/UpdetHealthDataDetailInformation/UpdetHealthDataDetailInformation";
import HelthCardPayment from "./Patient/HelthCardPayment/HelthCardPayment";
import RejectionPopup from "./Doctor/RejectionPopup/RejectionPopup";
import CaountUpcomingAppointment from "./Doctor/CaountUpcomingAppointment/CaountUpcomingAppointment";
import PrescriptionRejectList from "./Patient/PrescriptionRejectList/PrescriptionRejectList";
// import CutVedioCallpopup from "./Doctor/CutVedioCallpopup/CutVedioCallpopup";
import SendtoLebModel from "./Doctor/SendtoLebModel/SendtoLebModel";
import SendToPharmacy from "./Doctor/SendToPharmacy/SendToPharmacy";
import ZegoCloudVideoCall from "./Videocall/ZegoCloudVideoCall";
// import { KEY_USER_DATA } from "./Redux/Appconstant";
// import { store } from "./Redux/store";

// import OtherInformation from "./Auth/OtherInformation/OtherInformation";
// import HealthDataInformation from "./Auth/HealthDataInformation/HealthDataInformation";

const AllRoutes = () => {
  const { tokenexpire, setnotificationcount, settokenexpire } =
    useContext(GlobalContext);

  // const [role, setrole] = useState();
  // useEffect(() => {
  //   setrole(userdata?.basicInfo?.role);
  // }, [userdata]);

  const { response } = Fetchdata(NOTIFICATIONAPICOUNT, {}, "GET");

  useEffect(() => {
    setnotificationcount(response?.data?.notificationCount);
  }, [response]);

  const islogin = JSON.parse(localStorage.getItem("isloginmtd"));
  // const userdata = store.getState().session[KEY_USER_DATA];
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      location.pathname !== "/" &&
      location.pathname !== "/Login" &&
      location.pathname !== "/physician-register" &&
      location.pathname !== "/ForgotPassword" &&
      location.pathname !== "/Otp" &&
      location.pathname !== "/register" &&
      location.pathname !== "/AddBankAccount"
    ) {
      if (!islogin || islogin === null) {
        settokenexpire(true);
      }
    }
  }, [islogin]);

  return (
    <>
      <Routes>
        {/* CMS ROUTES */}
        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/TermsCondition" element={<TermsCondition />} />
        {/* CMS ROUTES END */}
        <Route path="/" element={<Splash />} />
        <Route path="/CorporateLoginPopup" element={<CorporateLoginPopup />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/Otp" element={<OtpInpuT />} />
        <Route path="/ForgotPassword" element={<ForgotPassword />} />
        <Route path="/Profilesidebar" element={<Profilesidebar />} />
        <Route path="/meeting" element={<ZegoCloudVideoCall />} />
        <Route path="/home-page" element={<Home />} />
        <Route path="/Home" element={<DoctorHome />} />
        <Route path="/editprofile" element={<Editprofile />} />
        {/* <Route path="/OtherInformation" element={<OtherInformation />} /> */}
        <Route path="/register" element={<YourProfile />} />
        <Route path="/Calender" element={<Calender />} />
        <Route path="/BookAnAppointment1" element={<BookAnAppointment1 />} />
        <Route
          path="/BookAnAppointment/Reasonforconsultation/select-symtoms"
          element={<BookAnAppointment5 />}
        />
        <Route path="/BookAnAppointment" element={<BookAnAppointment3 />} />
        <Route
          path="/BookAnAppointment/Reasonforconsultation"
          element={<ReasonForConsultation />}
        />
        <Route
          path="/BookAnAppointment/Reasonforconsultation/Other"
          element={<Other />}
        />
        <Route path="/Torso" element={<Torso />} />
        <Route path="/Addcard" element={<Stripewraper />} />
        <Route path="/Addhealthcard" element={<AddhealthCard />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/UpcomingAppointment" element={<UpcomingAppointment />} />
        <Route path="/waitingroom" element={<WaitingRoom />} />
        <Route path="/HealthData" element={<HealthData />} />
        <Route path="/HealthDataDetail" element={<HealthDataDetail />} />
        <Route path="/Profile" element={<HealthDataDetail />} />
        <Route path="/familymember" element={<FamilyMember />} />
        <Route path="/Add-family-member" element={<AddfamilyMember />} />
        <Route path="/Notifications" element={<Notifications />} />
        <Route path="/Settings" element={<Settings />} />
        <Route path="/ChangePassword" element={<ChangePassword />} />
        {/* <Route path="/WaitingRoom" element={<WaitingRoom />} /> */}
        <Route path="/RateDoctor" element={<RateDoctor />} />
        <Route path="/Congratulation" element={<AppointmentBooked />} />
        <Route path="/Completedappointment" element={<CompleteAppointment />} />
        <Route path="/PrescriptionDetail" element={<PrescriptionDetail />} />
        <Route path="/MyCards" element={<MyCards />} />
        <Route path="/SelectPrescription" element={<SelectPrescription />} />
        <Route path="/DoctorProfile" element={<DoctorProfile />} />
        <Route
          path="/UpcomingAppointmentCount"
          element={<UpcomingAppointment2 />}
        />
        <Route
          path="/OtherDiseasesPsychiatry"
          element={<OtherDiseasesPsychiatry />}
        />
        <Route
          path="/RescheduleAppointment"
          element={<RescheduleAppointment />}
        />
        <Route path="/FindMD" element={<FindMD />} />
        <Route path="/SelectProfile" element={<SelectProfile />} />
        <Route path="/PaymentMethod" element={<PaymentMethod />} />
        <Route path="/Health-CreditCard" element={<HealthcardCreditcard />} />
        <Route path="/Faq" element={<Faq />} />
        {/* <Route path="/Profile" element={<Profile />} /> */}
        -------------------------/* Doctor Flow*/-------------------------
        <Route path="/physician-register" element={<DoctorRegister />} />
        {/* <Route path="/CreateProfileDoctor" element={<CreateProfileDoctor />} />
        <Route path="/ProfessionalDoctor" element={<ProfessionalDoctor />} /> */}
        <Route path="/AddBankAccount" element={<AddBankAccount />} />
        <Route path="/Prescription" element={<Prescription />} />
        <Route path="/SelectLab" element={<SelectLab />} />
        <Route path="/ClinicalNote" element={<ClinicalNote />} />
        <Route path="/SickNote" element={<SickNote />} />
        <Route path="/SentLabPopup" element={<SentLabPopup />} />
        <Route path="/SickNotePopup" element={<SickNotePopup />} />
        <Route path="/WaitingRoomss" element={<WaitingRoomss />} />
        <Route path="/AvailabilityDoctor" element={<AvailabilityDoctor />} />
        <Route path="/Billing" element={<BillingDoctor />} />
        <Route path="/Refill-Request" element={<RefillRequest />} />
        <Route path="/myprofile" element={<GeneralInfo />} />
        <Route path="/MyProfileLicence" element={<MyProfileLicence />} />
        <Route
          path="/MyProfileProfessionalInfoDoctor"
          element={<MyProfileProfessionalInfoDoctor />}
        />
        <Route
          path="/CompleteAppointmentDetail"
          element={<CompleteAppointmentDetail />}
        />
        <Route path="/MyComponent" element={<MyComponent />} />
        <Route path="/ReferralDoctor" element={<ReferralDoctor />} />
        <Route path="/PendingRequests" element={<PendingRequestsDoctor />} />
        <Route
          path="/Completed-Appointment"
          element={<CompletedAppointmentDoctor />}
        />
        <Route path="/Notes" element={<Notes />} />
        <Route
          path="/Upcoming-Appointment"
          element={<UpcomingAppointmentDoctor />}
        />
        <Route
          path="/UpcomingAppointmentDetailDoctor"
          element={<UpcomingAppointmentDetailDoctor />}
        />
        <Route path="/Xrayultrasound" element={<Xrayultrasound />} />
        <Route path="/DoctorBook" element={<DoctorBook />} />
        <Route path="/Signature" element={<Signature />} />
        <Route path="/Cardiology" element={<Cardiology />} />
        <Route path="/Ctscan" element={<Ctscan />} />
        <Route path="/GeneralForm" element={<GeneralForm />} />
        <Route path="/OtherForm" element={<OtherForm />} />
        {/* <Route path="/CutVedioCallpopup" element={<CutVedioCallpopup />} /> */}
        <Route
          path="/GeneralPublicHealthForm"
          element={<GeneralPublicHealthForm />}
        />
        <Route path="/HIVForm" element={<HIVForm />} />
        <Route path="/DataSuccessfully" element={<DataSuccessfully />} />
        <Route path="/CTscenMRI" element={<CTscenMRI />} />
        <Route path="/Availablity" element={<Availablity />} />
        <Route path="/Thisispaid" element={<Thisispaid />} />
        <Route
          path="/AddDoctorInformation"
          element={<AddDoctorInformation />}
        />
        <Route
          path="/TowAddDoctorInformation"
          element={<TowAddDoctorInformation />}
        />
        <Route path="/EditProfileDoctor" element={<EditProfileDoctor />} />
        <Route path="/ProfessionalInfo" element={<ProfessionalInfo />} />
        <Route path="/IncompleteNotes" element={<IncompleteNotes />} />
        <Route path="/InvestigationDoctor" element={<InvestigationDoctor />} />
        <Route path="/LabNotes" element={<LabNotes />} />
        <Route path="/OntarioForm" element={<OntarioForm />} />
        <Route path="/PrescriptionNotes" element={<PrescriptionNotes />} />
        <Route path="/FindMDHead" element={<FindMDHead />} />
        <Route path="/AppoinmentCalender" element={<AppoinmentCalender />} />
        <Route path="/Cardiologest" element={<Cardiologest />} />
        <Route path="/CardiologestPop" element={<CardiologestPop />} />
        <Route path="/HelthCardPop" element={<HelthCardPop />} />
        <Route path="/HealthInsurance" element={<HealthInsurance />} />
        <Route path="/Doctorschedule" element={<Doctorschedule />} />
        <Route path="/SetAvailablity" element={<SetAvailablity />} />
        <Route path="/SelectedPharmacy" element={<SelectedPharmacy />} />
        <Route path="/Sendtoleb" element={<Sendtoleb />} />
        <Route path="/Pymentsuccess" element={<Pymentsucssas />} />
        <Route path="/DoctorWaitingroom" element={<DoctorWaitingroom />} />
        <Route path="/PendingInsurance" element={<PendingInsurance />} />
        <Route path="/PatientVidoCall" element={<PatientVidoCall />} />
        <Route path="/SicNotepay" element={<SicNotepay />} />
        <Route path="/SickNotePayment" element={<SickNotePayment />} />
        <Route path="/ConsultationHistory" element={<ConsultationHistory />} />
        <Route path="/UpdetProfile" element={<UpdetProfile />} />
        <Route path="/HelthCardPayment" element={<HelthCardPayment />} />
        <Route path="/RejectionPopup" element={<RejectionPopup />} />
        <Route path="/SendtoLebModel" element={<SendtoLebModel />} />
        <Route path="/SendToPharmacy" element={<SendToPharmacy />} />
        <Route
          path="/PrescriptionRejectList"
          element={<PrescriptionRejectList />}
        />
        <Route
          path="/CountUpcomingAppointment"
          element={<CaountUpcomingAppointment />}
        />
        <Route
          path="/UpdetHealthDataDetailInformation"
          element={<UpdetHealthDataDetailInformation />}
        />
        <Route
          path="/UpdetHealthDataDetail"
          element={<UpdetHealthDataDetail />}
        />
        <Route
          path="/RatingCompletedAppointment"
          element={<RatingCompletedAppointment />}
        />
        <Route
          path="/SickCompleteAppointment"
          element={<SickCompleteAppointment />}
        />
        <Route
          path="/BookandAppoinmentSlote"
          element={<BookandAppoinmentSlote />}
        />
      </Routes>
      {tokenexpire && <SessionExpiryPopup />}
    </>
  );
};

export default AllRoutes;
