import React, { useEffect, useRef, useState } from "react";
import "./FindMDHead.css";
import Cardimg from "../../Assets/Images/ForgotPasswordImg.avif";
import time from "../../Assets/Images/time_ic@3x.png";
import { Colors } from "../../Constant/Colors";
import Button from "../../Components/Button/Buttton";
import locationim from "../../Assets/Images/location_ic_sm@3x.png";
import { useLocation, useNavigate } from "react-router-dom";
import { FindMDHeadApi } from "../../Constant/Apiconstant";
import Fetchdata from "../../Constant/Fetchdata";
import { Headers } from "../../Constant/Fetchdata";
import { SimpleLoader } from "../../Constant/Loader";
import Header from "../../Components/Header/Header";
const FindMDHead = () => {
  const navigate = useNavigate();
  const [isSelected, setIsSelected] = useState({ data: null, index: null });
  const [Finedmd, setFinedmd] = useState([]);
  // console.log(isSelected, "isSelected");

  const handleCardClick = (itm, index) => {
    setIsSelected({ data: itm, index: index });
    // console.log(itm, "v");
  };

  const location = useLocation();
  const selectedsymtom = location.state?.selectedsymtom;
  const number = location.state?.num;
  // console.log(number, "number");

  const header = Headers();
  const params = {
    symptoms: selectedsymtom,
    page: 1,
  };
  const { response, isloading } = Fetchdata(FindMDHeadApi, params, "POST");

  useEffect(() => {
    if (response?.data) {
      setFinedmd(response.data.docs);
    }
  }, [response]);

  // console.log(response, "response");
  // console.log(response, "response");
  const handleclick = () => {
    navigate("/BookandAppoinmentSlote", {
      state: {
        isSelected,
        selectedsymtom,
        number,
      },
    });
  };
  return (
    <>
      <Header onClick={() => navigate(-1)} text={"Select doctor"} />{" "}
      <section className="FindMD-main-section-heading">
        {isloading ? (
          <SimpleLoader style={{ height: "300px" }} />
        ) : (
          <div className="FindMD-main-div-head">
            <>
              {Finedmd?.map((itm, index) => {
                {
                  /* console.log(itm); */
                }
                return (
                  <>
                    <div
                      className={`FindMD-img-contain-heading ${
                        isSelected.index == index ? "selected" : ""
                      }`}
                      onClick={() => handleCardClick(itm, index)}
                    >
                      <div>
                        <img
                          className="Cardimg-docter"
                          src={itm.basicInfo.image}
                          alt=""
                        />
                      </div>
                      <div className="Dorothy-Lucas-sapn-div">
                        <div className="Lucas-awy-main-div">
                          <div className="Dorothy-extra-div">
                            <span className="Dorothy-sapn">
                              {itm.basicInfo.fullName}
                            </span>
                            <span className="General-span">
                              {itm.speciality}
                            </span>
                          </div>
                        </div>
                        <div className="mt-2">
                          {itm?.consultationSchedule?.sessions?.length > 0 && (
                            <div>
                              <img className="time-img-css" src={time} alt="" />
                              <span className="Am-pm-span">
                                {itm.consultationSchedule.sessions[0].slotTime}
                              </span>{" "}
                              to{" "}
                              {itm.consultationSchedule.sessions.length > 1 && (
                                <span className="Am-pm-span">
                                  {
                                    itm.consultationSchedule.sessions.slice(
                                      -1
                                    )[0].slotTime
                                  }
                                </span>
                              )}
                            </div>
                          )}
                        </div>
                        <div className="">
                          <img
                            className="time-img-css"
                            src={locationim}
                            alt=""
                          />
                          <span className="Am-pm-span">
                            {" "}
                            {itm.basicInfo.location}
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </>
          </div>
        )}
        <button className="main-Next-type-button mb-3" onClick={handleclick}>
          Next
        </button>
      </section>
    </>
  );
};

export default FindMDHead;
