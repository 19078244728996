import React, { useState, useEffect, Fragment } from "react";
import "./GeneralInfo.css";
import MyProfileProfessionalInfoDoctor from "../MyProfileProfessionalInfoDoctor/MyProfileProfessionalInfoDoctor";
import MyProfileLicence from "../MyProfileLicence/MyProfileLicence";
import Header from "../../Components/Header/Header";
import { useNavigate } from "react-router-dom";
// import Cardimg from "../../Assets/Images/Doctorsto.avif";
import Fetchdata from "../../Constant/Fetchdata";
import { DOCTORGETPROFILE } from "../../Constant/Apiconstant";
import { Colors } from "../../Constant/Colors";
import { Skeleton } from "@mui/material";
import { Icons } from "../../Constant/Icons";
// import moment from "moment";

const GeneralInfo = () => {
  const navigate = useNavigate();
  const [Active, setActive] = useState(0);
  // const [Agees, setAges] = useState();

  const [doctorBook, setDoctorBook] = useState();
  // console.log(doctorBook, "doctorBook");
  const deta = [
    {
      id: 1,
      Name: "General Info",
    },
    {
      id: 2,
      Name: "Professional Info",
    },
    {
      id: 3,
      Name: "Licence",
    },
  ];
  const { response, isloading } = Fetchdata(DOCTORGETPROFILE, {}, "GET");
  // console.log(response, "response");

  useEffect(() => {
    if (response?.data) {
      setDoctorBook(response.data.basicInfo);
    }
  }, [response]);

  // const calculateage = (dob) => {
  //   let today = new Date();
  //   let birthdatobj = new Date(dob);
  //   let age = today.getFullYear() - birthdatobj.getFullYear();
  //   console.log(age);
  //   console.log(birthdatobj);
  //   console.log(dob);
  //   const month = today.getMonth() - birthdatobj.getMonth();
  //   if (month < 0 || (month === 0 && today.getDate() < birthdatobj.getDate())) {
  //     age--;
  //   }
  //   return age;
  // };

  const calculateage = (dobString) => {
    const birthDate = new Date(dobString?.split("-")?.reverse()?.join("-"));
    const currentDate = new Date();
    // Calculate age
    let age = currentDate.getFullYear() - birthDate.getFullYear();
    const monthDiff = currentDate.getMonth() - birthDate.getMonth();
    // Adjust age if birthday hasn't occurred yet this year
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  let age = calculateage(doctorBook?.dob);

  // useEffect(() => {
  //   const dob = moment(doctorBook?.dob, "DD-MM-YYYY");
  //   const now = moment();
  //   const age = now.diff(dob, "years");
  //   setAges(age);
  //   console.log(age, "years");
  // }, []);

  // console.log(doctorBook.dob, "dob");
  // const dobParts = doctorBook.dob.split("-");
  // const dob = new Date(dobParts[2], dobParts[1] - 1, dobParts[0]);
  // const now = new Date();
  // let age = now.getFullYear() - dob.getFullYear();
  // if (
  //   now.getMonth() < dob.getMonth() ||
  //   (now.getMonth() === dob.getMonth() && now.getDate() < dob.getDate())
  // ) {
  //   age--;
  // }

  // console.log(age, "jsjsj");

  const Updata = () => {
    return (
      <>
        <div className="edit-icon-mi">
          <div style={{ color: "white" }} className="mt-3 img-name-field-main">
            <div>
              {isloading ? (
                <Skeleton
                  width={80}
                  height={80}
                  variant="rounded"
                  style={{ backgroundColor: "grey" }}
                />
              ) : (
                <img
                  className="Updata-main-img"
                  src={response?.data?.basicInfo?.image}
                  alt=""
                />
              )}
            </div>
            <div className="General-span-Medicine-span">
              {isloading ? (
                <Skeleton
                  width={120}
                  height={25}
                  variant="text"
                  style={{ backgroundColor: "grey" }}
                />
              ) : (
                <span>{response?.data?.basicInfo?.fullName}</span>
              )}
              {isloading ? (
                <Skeleton
                  width={90}
                  height={25}
                  variant="text"
                  style={{ backgroundColor: "grey" }}
                />
              ) : (
                <span>{response?.data?.basicInfo?.gender}</span>
              )}
              <div className="point-4-span-div">
                <span>{Icons.ratingstar}</span>
                <span className="font-3-2-2">
                  {response?.data?.rating_details?.average_rating}.0
                </span>
                <span className="font-3-2-2-dot mr-5"></span>
                <div>
                  <span className="font-3-2-2">
                    {response?.data?.rating_details?.review_total_count}
                  </span>{" "}
                  <span className="dr-main-name-General">Reviews</span>
                </div>
              </div>
            </div>
          </div>
          <span onClick={() => navigate("/EditProfileDoctor")}>
            {Icons.editicon2}
          </span>
        </div>
      </>
    );
  };

  // console.log(response);
  return (
    <>
      <Header
        onClick={() => navigate("/Home")}
        text={"My profile"}
        extrafield={<Updata />}
        style={{ color: "white" }}
      />
      <section className="BillingDoctor-main-section">
        <div className="BillingDoctor-main-div">
          <div className="Professional-Licence-div mt-5">
            {deta.map((item, index) => {
              return (
                <Fragment key={index}>
                  <div className="line-and-span-dive">
                    <span
                      className="all-outlet-span"
                      onClick={() => setActive(index)}
                      style={{
                        color: Active == index ? Colors.blue : "",
                        borderBottom:
                          Active == index ? `2px solid ${Colors.blue}` : "",
                      }}
                    >
                      {item.Name}
                    </span>
                  </div>
                </Fragment>
              );
            })}
          </div>
          {Active === 0 ? (
            <>
              <div className="profile-age-div mt-3">
                <span className="age-main-span-add">Age</span>
                {isloading ? (
                  <Skeleton width={50} height={40} variant="text" />
                ) : (
                  <span>
                    {age}
                    {/* {response?.data?.basicInfo?.age}{" "}
                  {response?.data?.basicInfo?.age && "Years"} */}
                  </span>
                )}
              </div>
              <div className="Notifications-d-s-line mt-3"></div>
              <div className="profile-age-div mt-3">
                <span className="age-main-span-add">Email address</span>
                {isloading ? (
                  <Skeleton width={200} height={40} variant="text" />
                ) : (
                  <span>{response?.data?.basicInfo?.email}</span>
                )}
              </div>
              <div className="Notifications-d-s-line mt-3"></div>
              <div className="Email-and-dorothy-price  mt-3">
                <div className="profile-age-div">
                  <span className="age-main-span-add">Fax number</span>
                  {isloading ? (
                    <Skeleton width={100} height={40} variant="text" />
                  ) : (
                    <span>{response?.data?.basicInfo?.faxNumber}</span>
                  )}
                </div>
                {/* <div>
                  <span className="main-span-doluor">$ 30</span>
                </div> */}
              </div>
              <div className="Notifications-d-s-line mt-3"></div>
              <div className="profile-age-div mt-3">
                <span className="age-main-span-add"> Phone number</span>
                {isloading ? (
                  <Skeleton width={100} height={40} variant="text" />
                ) : (
                  <span>{response?.data?.basicInfo?.phoneNumber}</span>
                )}
              </div>
              <div className="Notifications-d-s-line mt-3"></div>
              <div className="GeneralInfo-main-Change-btn-div">
                <button
                  className="GeneralInfo-main-Change"
                  onClick={() => navigate("/SetAvailablity")}
                >
                  Set availablity
                </button>
              </div>
            </>
          ) : Active === 1 ? (
            <MyProfileProfessionalInfoDoctor response={response?.data} />
          ) : Active === 2 ? (
            <MyProfileLicence response={response?.data} />
          ) : null}
        </div>
      </section>
    </>
  );
};

export default GeneralInfo;
