import React, { useState, useContext } from "react";
// import "./CompleteAppointmentDetail.css";
// import { BsFillStarFill } from "react-icons/bs";
import "./SickCompleteAppointment.css";
import Header from "../../Components/Header/Header";
import { useLocation, useNavigate } from "react-router-dom";
// import Health from "../../Assets/Images/HealthDataImg.avif";
import { Dateformat } from "../../Components/Dateformat/Dateformat";
import { FaRegFilePdf } from "react-icons/fa6";
import RateDoctor from "../RateDoctor/RateDoctor";
// import EnterMedicineName from "../../Doctor/EnterMedicineName/EnterMedicineName";
import DataSuccessfully from "../../Doctor/DataSuccessfully/DataSuccessfully";
import { GlobalContext } from "../../Context/GlabalContext";
// import PaymentMethod from "../PaymentMethod/PaymentMethod";
import SicNotepay from "../SicNotepay/SicNotepay";

const SickCompleteAppointment = () => {
  const navigate = useNavigate();
  const route = useLocation();
  const maindata = route?.state?.itm;

  // console.log(maindata, "maindata");

  const [save, setsave] = useState("");
  // console.log(save, "save");
  const [Popup, setPopup] = useState(false);

  const [payPopup, setpayPopup] = useState(false);

  // const [selected, setSelected] = useState(0);
  const [show, setShow] = useState(false);
  // const [maindataitm, setMaindataitm] = useState(maindata);
  const [isChecked, setIsChecked] = useState(false);
  const { SickCompleteId, setSickCompleteId } = useContext(GlobalContext);
  const [Idss, setIdss] = useState([]);

  // console.log(SickCompleteId, "SickCompleteId");
  // console.log(maindataitm, "maindataitm");

  // const StarGenerator = (numofstar = 5) => {
  //   // console.log(selected, "seleted");
  //   return Array(numofstar)
  //     .fill()
  //     .map((item, i) => (
  //       <Star
  //         key={i}
  //         selected={selected > i}
  //         onSelected={() => setSelected(i + 1)}
  //       />
  //     ));
  // };

  // const Star = ({ selected, onSelected }) => {
  //   return (
  //     <BsFillStarFill
  //       className="fastar-mnb-CompleteAppointmentDetail"
  //       color={selected ? "goldenrod" : "#E0DBCE"}
  //       onClick={onSelected}
  //     />
  //   );
  // };

  const formated = Dateformat(maindata?.appointment?.utc_time);

  // console.log(formated, "formated");
  // const Updata = () => {
  //   // if (!Array.isArray(maindataitm)) {
  //   //   console.error("maindataitm is not an array:", maindataitm);
  //   //   return;
  //   // }

  //   // const handleclick = (item) => {
  //   //   console.log(item);
  //   //   setprecriptionselecteddata((prev) => {
  //   //     const currentPrescriptionIds =
  //   //       prev && prev.prescriptionIds ? prev.prescriptionIds : [];

  //   //     const newPrescriptionIds = currentPrescriptionIds.includes(item._id)
  //   //       ? currentPrescriptionIds.filter((id) => id !== item._id)
  //   //       : [...currentPrescriptionIds, item._id];

  //   //     // Assuming setIdss directly sets the state with an array of IDs
  //   //     setIdss(newPrescriptionIds);

  //   //     // Return the updated state
  //   //     return {
  //   //       ...prev,
  //   //       prescriptionIds: newPrescriptionIds,
  //   //     };
  //   //   });
  //   // };

  //   return (
  //     <>
  //       <div className="FindMD-img-contain mt-4">
  //         <div>
  //           <img
  //             className="Cardimg-docter"
  //             src={maindata?.appointment?.doctor_id?.basicInfo?.image}
  //             alt=""
  //           />
  //           <div></div>
  //         </div>
  //         <div className="Dorothy-Lucas-sapn-div">
  //           <div className="Height-awy-main-div">
  //             <div className="Dorothy-extra-div">
  //               <span
  //                 className="Dorothy-sapn"
  //                 style={{
  //                   color: "white",
  //                   fontSize: "20px",
  //                   fontWeight: "700",
  //                 }}
  //               >
  //                 {maindata?.appointment?.doctor_id?.basicInfo?.firstName} {""}
  //                 {maindata?.appointment?.doctor_id?.basicInfo?.lastName}
  //               </span>

  //               <span>{maindata?.appointment?.doctor_id?.speciality}</span>
  //               <div className="point-4-span-div">
  //                 <svg
  //                   width="20"
  //                   height="20"
  //                   viewBox="0 0 50 49"
  //                   fill="none"
  //                   xmlns="http://www.w3.org/2000/svg"
  //                 >
  //                   <g clipPath="url(#clip0_26_1451)">
  //                     <path
  //                       d="M24.5647 34.7084L12.5638 42.0379L15.8264 28.3588L5.14844 19.2121L19.1645 18.0892L24.5647 5.10419L29.9649 18.0892L43.983 19.2121L33.303 28.3588L36.5656 42.0379L24.5647 34.7084Z"
  //                       fill="#E4AE2F"
  //                     />
  //                   </g>
  //                   <defs>
  //                     <clipPath id="clip0_26_1451">
  //                       <rect
  //                         width="49"
  //                         height="49"
  //                         fill="white"
  //                         transform="translate(0.0625)"
  //                       />
  //                     </clipPath>
  //                   </defs>
  //                 </svg>
  //                 <span className="font-3-2-2">
  //                   {
  //                     maindata?.appointment?.doctor_id?.rating_details
  //                       .average_rating
  //                   }
  //                   .0
  //                 </span>
  //                 <span className="font-3-2-2-dot mr-5"></span>
  //                 <div>
  //                   <span className="font-3-2-2">
  //                     {
  //                       maindata?.appointment?.doctor_id?.rating_details
  //                         .review_total_count
  //                     }
  //                   </span>
  //                   <span className="dr-main-name-General px-1">Reviews</span>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </>
  //   );
  // };

  const hello = "Reqvriy";

  const handleCheckboxClick = (item) => {
    setSickCompleteId((prev) => {
      const currentPrescriptionIds =
        prev && prev.prescriptionIds ? prev.prescriptionIds : [];

      const newPrescriptionIds = currentPrescriptionIds.includes(item._id)
        ? currentPrescriptionIds.filter((id) => id !== item._id)
        : [...currentPrescriptionIds, item._id];

      setIdss(newPrescriptionIds);
      return {
        ...prev,
        prescriptionIds: newPrescriptionIds,
      };
    });
    setIsChecked((prev) => !prev);
    setsave({
      Itm: item,
      code: hello,
      Idsss: maindata,
    });
  };

  const handleButtonClick = () => {
    if (isChecked) {
      navigate("/PaymentMethod", {
        state: {
          save,
        },
      });
    } else {
      setPopup(true);
    }
  };

  // console.log(maindata);
  return (
    <>
      <Header
        onClick={() => navigate(-1)}
        text={"Completed appointment"}
        style={{ color: "white" }}
        // extrafield={<Updata />}
      />
      <section className="BillingDoctor-main-section">
        <div className="BillingDoctor-main-div">
          <div className="Email-and-dorothy-price  mt-3">
            <div className="profile-age-div">
              <span className="age-main-span-add">Patient Name </span>
              <span className="Payment-all-contint-span">
                {" "}
                {maindata?.appointment?.patientName}
              </span>
            </div>
          </div>
          <div className="Notifications-d-s-line mt-3"></div>
          <div className="Email-and-dorothy-price  mt-3">
            <div className="profile-age-div">
              <span className="age-main-span-add">
                Consultation Type & Time
              </span>
              <span className="Payment-all-contint-span">{formated}</span>
            </div>
          </div>
          <div className="Notifications-d-s-line mt-3"></div>

          <div className="Email-and-dorothy-price  mt-3">
            <div className="profile-age-div">
              <span className="age-main-span-add">Payment Method</span>
              <span className="Payment-all-contint-span">
                {" "}
                {maindata?.appointment?.choose_payment_method}
              </span>
            </div>

            <span className="age-Consultation-span-add">
              {" "}
              {maindata?.appointment?.payment_currency}{" "}
              {maindata?.appointment?.consultation_fee}
            </span>
          </div>
          <div className="Notifications-d-s-line mt-3"></div>
          <div className="profile-age-div mt-3">
            <span className="age-main-span-add">Status</span>
            <span className="Payment-all-contint-span">
              {maindata?.appointment?.status}
            </span>
          </div>
          <div className="Notifications-d-s-line mt-3"></div>
          <div className="profile-age-div mt-3">
            <span className="age-main-span-add">Address</span>
            <span className="Payment-all-contint-span">
              {maindata?.appointment?.doctor_id?.basicInfo?.address}
            </span>
          </div>
          <div className="Notifications-d-s-line mt-3"></div>
          <div className="profile-age-div mt-3">
            <span className="age-main-span-add">
              Notes
              <div>
                <span className="Payment-all-contint-span">
                  {maindata.Note}
                </span>
              </div>
            </span>
          </div>
          <div className="Notifications-d-s-line mt-3"></div>
          {/* <div className="profile-age-div mt-3">
            <div className="Result-main-second-History">
              <span className="age-main-span-add">Result</span>
              <span
                onClick={() => navigate("/ConsultationHistory")}
                className="age-History-span-add"
              >
                History
              </span>
            </div>
            <div className="FaRegFilePdf-main-notes-result">
              {maindata?.result.map((item, index) => {
                return (
                  <div className="FaRegFilePdf-FaRegFilePdf-span">
                    <a href={item}>
                      <FaRegFilePdf className="Sick-NOte-mmain" />
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="Notifications-d-s-line mt-3"></div> */}
          {/* <div className="profile-age-div mt-3">
                <span className="age-main-span-add">Sick Note </span>
                <div className="FaRegFilePdf-FaRegFilePdf-span">
                  {maindata?.appointment?.sickNote?.sickNoteShow ? (
                    <a href={maindata?.appointment?.sickNote?.sickNotePdf}>
                      <FaRegFilePdf className="Sick-NOte-mmain" />
                    </a>
                  ) : (
                    <>
                      <span onClick={() => setpayPopup(true)}>
                        <FaRegFilePdf className="Sick-NOte-mmain" />
                      </span>
                    </>
                  )}
                </div>
              </div>
              <div className="Notifications-d-s-line mt-3"></div>
              <div className="profile-age-div mt-3">
                <span className="age-main-span-add">
                  Prescription provided by doctor
                </span>
                <div className="d-flex flex-column gap-2">
                  {maindata?.appointment?.prescriptionData?.map((item, index) => {
                    return (
                      <div key={item._id}>
                        <label
                          htmlFor="check"
                          className="d-flex align-items-center gap-2"
                        >
                          <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={() => handleCheckboxClick(item)}
                            className="checkboxpre"
                          />
                          <span
                            className="Lorem-main-span"
                            id="check"
                          >{`${item.nameOfMedicine} ${item.duration} ${item.doseUnit} ${item.frequency} x ${item.duration} ${item.durationUnit}`}</span>
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="Notifications-d-s-line mt-3"></div>
              <div className="Prescription-Give-main-btn">
                  <button
                    onClick={() => setShow(true)}
                    className="Submit-Main-btn-Bim"
                  >
                    Give Rating
                  </button>
                  <button onClick={handleButtonClick} className="Submit-Main-btn-Bim">
                    Renew Prescription
                  </button>
                </div> */}
        </div>
      </section>
      {show && (
        <RateDoctor
          show={show}
          setShow={setShow}
          handleCloseee={() => setShow(false)}
          maindataitm={maindata}
        />
      )}

      {Popup && (
        <DataSuccessfully
          Popup={Popup}
          handleCloseeee={() => setPopup(false)}
          setPopup={setPopup}
          text={"Please select Prescription, which You want to renew."}
        />
      )}
      <SicNotepay
        payPopup={payPopup}
        setpayPopup={setpayPopup}
        text={"You need to pay 20 CAD for this SickNote."}
        handleCloseeee={() => setpayPopup(false)}
        // handlePay={() => "/SickNotePayment"}
        maindataitm={maindata}
      />
    </>
  );
};

export default SickCompleteAppointment;
