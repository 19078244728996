/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
/* eslint-disable no-labels */
import React, { Fragment, useEffect, useState } from "react";
import "./Settings.css";
import Notifications from "../../Assets/Images/notification_ic@3x.png";
import save from "../../Assets/Images/saved_payment_detail_ic@3x.png";
import contact from "../../Assets/Images/contact_us_ic@3x.png";
import About from "../../Assets/Images/about_us_ic@3x.png";
import Help from "../../Assets/Images/help_and_faq_ic@3x.png";
import trams from "../../Assets/Images/terms_and_conditions_ic@3x.png";
import Privece from "../../Assets/Images/terms_and_conditions_ic@3x.png";
import Cheng from "../../Assets/Images/change_pass_ic@3x.png";
import Rate from "../../Assets/Images/rate_the_app@3x.png";
import Log from "../../Assets/Images/logout_ic@3x.png";
import { Headers } from "../../Constant/Fetchdata";
import Header from "../../Components/Header/Header";
import { Icons } from "../../Constant/Icons";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Switch,
} from "@mui/material";
import { store } from "../../Redux/store";
import { setSessionField } from "../../Redux/SessionAction";
import { IS_LOGGED_IN, KEY_USER_DATA } from "../../Redux/Appconstant";
import {
  DOCTORSETNOTIFICATIONAPI,
  PATIENTSETNOTIFICATIONAPI,
} from "../../Constant/Apiconstant";
import axios from "../../Constant/ApiUrl";

const Settings = () => {
  const data = [
    {
      img: save,
      Name: "Saved Payment Details",
      pathname: "/PaymentMethod",
    },
    {
      img: contact,
      Name: "Contact Us",
      pathname: "/ContactUs",
    },
    {
      img: About,
      Name: "About Us",
      pathname: "/AboutUs",
    },
    {
      img: Help,
      Name: "Help & FAQ",
      pathname: "/Faq",
    },
    {
      img: trams,
      Name: "Terms & Conditions",
      pathname: "/TermsCondition",
    },
    {
      img: Privece,
      Name: "Privacy Policy",
      pathname: "/PrivacyPolicy",
    },
    {
      img: Cheng,
      Name: "Change Password",
      pathname: "/ChangePassword",
    },
    {
      img: Rate,
      Name: "Rate the App",
    },
  ];

  const userDATA = store.getState().session[KEY_USER_DATA];
  const userrole = userDATA?.basicInfo?.role;
  // const notificationstart = userDATA.basicInfo.notificationStart;
  const checkvalue = JSON.parse(localStorage.getItem("notification"));
  const [logoutalert, setlogoutalert] = useState(false);
  const [checkedd, setchecked] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (checkvalue === null) {
      setchecked(false);
    } else {
      setchecked(checkvalue);
    }
  }, [checkvalue]);

  const handlelogout = () => {
    setlogoutalert(false);
    store.dispatch(setSessionField(IS_LOGGED_IN, false));
    store.dispatch(setSessionField(KEY_USER_DATA, {}));
    localStorage.setItem("isloginmtd", JSON.stringify(false));
    navigate("/");
  };
  const header = Headers();
  const handlenotification = async (e) => {
    const checked = e.target.checked;
    setchecked(checked);
    localStorage.setItem("notification", checked);
    let url =
      userrole === "patient"
        ? PATIENTSETNOTIFICATIONAPI
        : userrole === "physician"
        ? DOCTORSETNOTIFICATIONAPI
        : null;
    try {
      const res = await axios.post(
        url,
        {
          notificationStart: checked,
        },
        header
      );
      // console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  // console.log(checkedd);
  return (
    <>
      <Header onClick={() => navigate(-1)} text={"Settings"} />
      <section className="Settings-main-section">
        <div className="Settings-main-div">
          <div className="togal-btn-puch-Notification">
            <div className="Push-noti-fication-div">
              <img
                className="Notifications-main-img"
                src={Notifications}
                alt=""
              />
              <span className="span-puchh-not">Push Notification</span>
            </div>
            <div>
              <Switch
                checked={checkedd || false}
                onChange={handlenotification}
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>
          </div>
          <div className="Notifications-d-s-line mt-3"></div>
          {data.slice(userrole === "physician" ? 1 : 0).map((itm, index) => {
            const save = itm.Name;
            return (
              <Fragment key={index}>
                <div
                  className="togal-btn-puch-Notification  mt-3"
                  onClick={() => {
                    if (itm.Name === "Saved Payment Details") {
                      navigate(itm.pathname, { state: { save } });
                    } else {
                      navigate(itm.pathname);
                    }
                  }}
                  key={index}
                  style={{ cursor: "pointer" }}
                >
                  <div className="Push-noti-fication-div">
                    <img
                      className="Notifications-main-img"
                      src={itm.img}
                      alt=""
                    />
                    <span className="span-puchh-not">{itm.Name}</span>
                  </div>
                  <div>{Icons.settingarrow}</div>
                </div>
                <div className="Notifications-d-s-line mt-3"></div>
              </Fragment>
            );
          })}
          <div
            className="togal-btn-puch-Notification mt-3"
            onClick={() => setlogoutalert(true)}
            style={{
              cursor: "pointer",
              width: "max-content",
              alignSelf: "self-start",
            }}
          >
            <div className="Push-noti-fication-div">
              <img className="Notifications-main-img" src={Log} alt="" />
              <span className="span-puchh-not">Logout</span>
            </div>
          </div>
          <div className="Notifications-d-s-line mt-3"></div>
        </div>
      </section>
      {logoutalert && (
        <Dialog
          open={logoutalert}
          //   onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="d-flex mt-3  align-items-center justify-content-center">
            <h1
              style={{
                borderRadius: "50%",
                width: "45px",
                height: "45px",
                fontSize: "25px",
                display: "grid",
                placeItems: "center",
                border: "1px solid grey",
              }}
            >
              ?
            </h1>
          </div>
          <DialogContent>
            <DialogContentText
              style={{ textAlign: "center" }}
              id="alert-dialog-description"
            >
              Are you sure you want to
              <br />
              Logout?
            </DialogContentText>
          </DialogContent>
          <div style={{ textAlign: "center", paddingBottom: "1rem" }}>
            <Button onClick={() => setlogoutalert(false)}>No</Button>
            <Button onClick={handlelogout}>Yes</Button>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default Settings;

// Join Two Arrays by ID:
// Input:
// let arr1 = [
//   { id: 1, x: 1 },
//   { id: 2, x: 9 },
// ];
// let arr2 = [{ id: 3, x: 5 }];

// Output: [
//   { id: 1, x: 1 },
//   { id: 2, x: 9 },
//   { id: 3, x: 5 },
// ];
// const joinarray = (a, b) => {
//   let newrray = [];
//   let onemorearrry = [];
//   a.forEach((element) => {
//     newrray.push(element);
//   });
//   b.forEach((element) => {
//     newrray.push(element);
//   });
//   newrray.forEach((element) => {
//     onemorearrry.push([...new Set([...element])]);
//   });
//   return onemorearrry;
// };

// arr1 = [
//   { id: 1, x: 2, y: 3 },
//   { id: 2, x: 3, y: 6 },
// ];
// arr2 = [
//   { id: 2, x: 10, y: 20 },
//   { id: 3, x: 0, y: 0 },
// ];
// const mergeArrays = (arr1, arr2) => {
//   const combinedArray = [...arr1, ...arr2];
//   const resultArray = {};
//   combinedArray.map((i) => (resultArray[i.id] = i));
//   console.log(Object.values(resultArray));
// };
// Output: [
//   { id: 1, x: 2, y: 3 },
//   { id: 2, x: 10, y: 20 },
//   { id: 3, x: 0, y: 0 },
// ];
// Input: arr1 = [{ id: 1, b: { b: 94 }, v: [4, 3], y: 48 }];
// arr2 = [{ id: 1, b: { c: 84 }, v: [1, 3] }];
// Output: [{ id: 1, b: { c: 84 }, v: [1, 3], y: 48 }];
// mergeArrays(arr1, arr2);
