import "./Profilesidebar.css";
import { NavLink, useLocation } from "react-router-dom";
import yallow from "../../Assets/Images/ForgotPasswordImg.avif";

const Profilesidebar = () => {
  const location = useLocation();
  const MenuData = [
    {
      img: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_14_1844)">
            <path
              d="M13 21V11H21V21H13ZM3 13V3H11V13H3ZM9 11V5H5V11H9ZM3 21V15H11V21H3ZM5 19H9V17H5V19ZM15 19H19V13H15V19ZM13 3H21V9H13V3ZM15 5V7H19V5H15Z"
              fill="#3620FF"
            />
          </g>
          <defs>
            <clipPath id="clip0_14_1844">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      text: "Dashboard",
      link: "/Profile/Dashboard",
    },
    {
      img: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_16_2713)">
            <path
              d="M6.414 16L16.556 5.85802L15.142 4.44402L5 14.586V16H6.414ZM7.243 18H3V13.757L14.435 2.32202C14.6225 2.13455 14.8768 2.02924 15.142 2.02924C15.4072 2.02924 15.6615 2.13455 15.849 2.32202L18.678 5.15102C18.8655 5.33855 18.9708 5.59286 18.9708 5.85802C18.9708 6.12319 18.8655 6.37749 18.678 6.56502L7.243 18ZM3 20H21V22H3V20Z"
              fill="#3620FF"
            />
          </g>
          <defs>
            <clipPath id="clip0_16_2713">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      text: "Edit Profile",
      link: "/Profile/EditProfile",
    },
    {
      img: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12H4C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C9.25022 4 6.82447 5.38734 5.38451 7.50024L8 7.5V9.5H2V3.5H4L3.99989 5.99918C5.82434 3.57075 8.72873 2 12 2ZM13 7L12.9998 11.585L16.2426 14.8284L14.8284 16.2426L10.9998 12.413L11 7H13Z"
            fill="#3620FF"
          />
        </svg>
      ),
      text: "Order History",
      link: "/Profile/OrderHistory",
    },
    {
      img: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_18_3434)">
            <path
              d="M21 20H23V22H1V20H3V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V20ZM19 20V4H5V20H19ZM8 11H11V13H8V11ZM8 7H11V9H8V7ZM8 15H11V17H8V15ZM13 15H16V17H13V15ZM13 11H16V13H13V11ZM13 7H16V9H13V7Z"
              fill="#3620FF"
            />
          </g>
          <defs>
            <clipPath id="clip0_18_3434">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      text: "My Address",
      link: "/Profile/MyAddresses",
    },
    {
      img: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.1384 3C21.4146 3 21.6385 3.22386 21.6385 3.5C21.6385 3.58701 21.6157 3.67252 21.5725 3.74807L18 10L21.5725 16.2519C21.7095 16.4917 21.6262 16.7971 21.3865 16.9341C21.3109 16.9773 21.2254 17 21.1384 17H4V22H2V3H21.1384ZM18.5536 5H4V15H18.5536L15.6965 10L18.5536 5Z"
            fill="#3620FF"
          />
        </svg>
      ),
      text: "Reporting",
    },
    {
      img: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_15_998)">
            <path
              d="M6 8V7C6 5.4087 6.63214 3.88258 7.75736 2.75736C8.88258 1.63214 10.4087 1 12 1C13.5913 1 15.1174 1.63214 16.2426 2.75736C17.3679 3.88258 18 5.4087 18 7V8H20C20.2652 8 20.5196 8.10536 20.7071 8.29289C20.8946 8.48043 21 8.73478 21 9V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V9C3 8.73478 3.10536 8.48043 3.29289 8.29289C3.48043 8.10536 3.73478 8 4 8H6ZM19 10H5V20H19V10ZM11 15.732C10.6187 15.5119 10.3207 15.1721 10.1522 14.7653C9.98376 14.3586 9.9542 13.9076 10.0681 13.4823C10.1821 13.057 10.4332 12.6813 10.7825 12.4132C11.1318 12.1452 11.5597 11.9999 12 11.9999C12.4403 11.9999 12.8682 12.1452 13.2175 12.4132C13.5668 12.6813 13.8179 13.057 13.9319 13.4823C14.0458 13.9076 14.0162 14.3586 13.8478 14.7653C13.6793 15.1721 13.3813 15.5119 13 15.732V18H11V15.732ZM8 8H16V7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7V8Z"
              fill="#3620FF"
            />
          </g>
          <defs>
            <clipPath id="clip0_15_998">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      text: "Change Password",
    },
  ];
  return (
    <>
      <div className="main_div_sidebar">
        <div className="submain_div">
          <div className="profilepic_box">
            <div className="img_box_profile_pic">
              <img src={yallow} alt="" />
            </div>
            <div className="profile_title_div">
              <h1>Jenny Wilson</h1>
            </div>
          </div>
          <nav className="w-auto d-flex flex-column gap-3">
            {MenuData.map((item, index) => {
              return (
                <NavLink
                  style={{
                    // width: "max-content",
                    textDecoration: "none ",
                  }}
                  key={index}
                  to={item.link}
                  className="mainnav"
                >
                  <div className="MAin-link gap-4">
                    <div
                      className={
                        location.pathname == item.link
                          ? "SideBarIconActive SideBarIcon"
                          : "SideBarIcon"
                      }
                    >
                      {item.img}
                    </div>
                    <span style={{ width: "100%" }} className="SideBarTxt">
                      {item.text}
                    </span>
                  </div>

                  <div className="line_div_side">
                    <div className="inerline_box"></div>
                  </div>
                </NavLink>
              );
            })}
          </nav>
        </div>
      </div>
    </>
  );
};

export default Profilesidebar;
