import React, { useRef, useState, useEffect, useContext } from "react";
// import AppoinmentCalender from "../AppoinmentCalender/AppoinmentCalender";
import "./BookandAppoinmentSlote.css";
import {
  AppoinmentCalenderApi,
  bookappointmentLink,
  Pymentbookappointment,
  reschedulebookappointmentApi,
} from "../../Constant/Apiconstant";
import { Swiper, SwiperSlide } from "swiper/react";
import "react-datepicker/dist/react-datepicker.css";
import Fetchdata from "../../Constant/Fetchdata";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { GlobalContext } from "../../Context/GlabalContext";
import moment from "moment";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import "moment-timezone";
// import { isAfter } from "moment";
import axios from "../../Constant/ApiUrl";
import { Headers } from "../../Constant/Fetchdata";
import Loader, { SimpleLoader } from "../../Constant/Loader";
import Header from "../../Components/Header/Header";
import { Icons } from "../../Constant/Icons";
import { PaystackButton } from "react-paystack";
import { store } from "../../Redux/store";
import { KEY_USER_DATA, NUMBER } from "../../Redux/Appconstant";
import { setSessionField } from "../../Redux/SessionAction";

function generateMonthDates(year, month) {
  const today = new Date();
  const currentDay = today.getDate();

  // Calculate next month's year and month
  const nextMonthYear = month === 11 ? year + 1 : year;
  const nextMonth = (month + 1) % 12;

  // Calculate the end date as the same day of next month
  const endDate = new Date(nextMonthYear, nextMonth, currentDay);

  const dates = [];
  let currentDate = new Date(year, month, currentDay);

  while (currentDate <= endDate) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
}

const BookandAppoinmentSlote = () => {
  const [showVirtualTimes, setShowVirtualTimes] = useState(false);
  const [showInPersonTimes, setShowInPersonTimes] = useState(false);
  // const [isToggled, setIsToggled] = useState(false);
  // const [isChecked, setIsChecked] = useState(false);
  // const [selectedDays, setSelectedDays] = useState([]);
  const [virtualTimes, setVirtualTimes] = useState([]);
  const [inPersonTimes, setInPersonTimes] = useState([]);
  // console.log(selectedDays, "selectedDays");
  //   const route = useLocation();
  //   const maindata = route?.state;
  //   console.log(maindata, "Okid");
  const [selectedDateClass, setSelectedDateClass] = useState("");
  const [image, setimage] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  // console.log(moment(selectedDate).format("DD-MM-YYYY"), "selectedDate");
  const [slotetype, setslotetype] = useState(new Date());
  //   console.log(slotetype, "slotetype");
  const [isOpen, setIsOpen] = useState(false);
  const [data, setdata] = useState(image[0]);
  const [current, setcurrent] = useState(0);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setloading] = useState(false);
  const [currentTime, setCurrentTime] = useState(moment());

  // console.log(currentTime, "currentTime");
  const [selectedTimes, setSelectedTimes] = useState([]);

  // console.log(selectedTimes, "selectedTimes");
  // console.log(currentTime, "currentTime");

  // const [selectedDates, setSelectedDates] = useState([]);
  const [selectedSession, setSelectedSession] = useState("morning");
  const { Consultation, FindMDFemaliy, setFindMDFemaliy } =
    useContext(GlobalContext);
  // console.log(Consultation, "Consultation");
  // console.log(selectedSession, "selectedSession");
  const navigate = useNavigate();
  const route = useLocation();
  const location = useLocation();

  let arrayOfObjects = selectedTimes?.map((time) => ({ time: time }));
  const getObjectTime = arrayOfObjects?.length ? arrayOfObjects[0]?.time : null;
  // console.log(getObjectTime, "getObjectTime");

  const RescheduleData = location?.state?.DetaUpcoming;

  const UTC = RescheduleData?.utc_time;

  const utcDate = new Date(UTC);

  const formattedTime = utcDate.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  const userDATA = store.getState().session[KEY_USER_DATA];

  const maindata = route?.state;
  const Numbar = location?.state?.number;
  // const detailItm = location?.state?.itm;1
  // console.log(detailItm, " detailItm");
  const OkayData = route?.state;

  const PathNamere = location?.state?.PathSedul;
  // console.log(PathNamere, "PathNamere");
  const maindataitm = OkayData?.isSelected?.data;

  const totalSlides = 15;
  const btnref = useRef(null);

  if (startDate === null || startDate === undefined) {
    const currentDate = new Date();
    setStartDate(currentDate);
  }
  const today = new Date();
  const currentYear = today.getFullYear();

  const monthDates = generateMonthDates(currentYear, currentMonth.getMonth());

  // console.log(selectedDates);
  useEffect(() => {
    const currentDate = new Date();
    setStartDate(currentDate); // Set the startDate to the current date
  }, []);

  // const convertToEST = (date) => {
  //   return moment(date).tz("America/New_York").format("DD-MM-YYYY"); // Convert to EST
  // };

  // const convertedDates = selectedDates.map((date) => {
  //   let dd = new Date(date);
  //   let my = moment().set({
  //     date: dd.getDate(),
  //     month: dd.getMonth(),
  //     year: dd.getFullYear(),
  //   });
  //   console.log("Mydate", my.format("DD-MM-YYYY"));
  //   return moment(my).tz("America/New_York").format("DD-MM-YYYY");
  // });

  const handleNextMonth = () => {
    const nextMonth = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() + 1
    );
    setCurrentMonth(nextMonth);
    // btnref.current.swiper.slideNext();
  };

  const handleNextSlide = () => {
    const nextSlide = currentSlide + 1;
    if (nextSlide < totalSlides) {
      setCurrentSlide(nextSlide);
    } else {
      setIsDisabled(true);
    }
  };
  const length = image.length;
  const nextslide = () => {
    btnref.current.swiper.slideNext();
    handleNextSlide();
    setcurrent(current === length - 1 ? 0 : current + 1);
  };
  const prevslide = () => {
    btnref.current.swiper.slidePrev();
    setcurrent(current === 0 ? length - 1 : current - 1);
  };

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleDateClick = (date) => {
    const currentDate = new Date();
    const isCurrentDate =
      date.getFullYear() === currentDate.getFullYear() &&
      date.getMonth() === currentDate.getMonth() &&
      date.getDate() === currentDate.getDate();
    const isSelected =
      selectedDate && selectedDate.getTime() === date.getTime();
    setSelectedSession("morning");
    // Remove "selected-date" class from the previously selected date
    const prevSelectedDateElement = document.querySelector(
      ".Twent_july_spann_divv_swiper.selected-date"
    );
    if (prevSelectedDateElement) {
      prevSelectedDateElement.classList.remove("selected-date");
    }
    if (isCurrentDate) {
      setSelectedDate(isSelected ? null : date);
      setSelectedTimes(isSelected ? [] : []);
      setSelectedDateClass(isSelected ? "" : "current-date");
    } else if (date >= currentDate) {
      setSelectedDate(isSelected ? null : date);
      setSelectedTimes(isSelected ? [] : []);
      setSelectedDateClass(isSelected ? "" : "current-date");
    }
  };

  // Get the user's time zone offset in minutes
  var timeZoneOffset = new Date().getTimezoneOffset();

  // Convert the offset to hours and minutes
  var hours = Math.floor(Math.abs(timeZoneOffset) / 60);
  var minutes = Math.abs(timeZoneOffset) % 60;

  // Determine if the offset is positive or negative
  var offsetSign = timeZoneOffset > 0 ? "-" : "+";

  // Format the time zone string (e.g., "+05:30" or "-03:00")
  var timeZoneString =
    offsetSign + ("0" + hours).slice(-2) + ":" + ("0" + minutes).slice(-2);

  const currentDate = new Date();
  const currentDateIndex = monthDates.findIndex(
    (date) =>
      date.getFullYear() === currentDate.getFullYear() &&
      date.getMonth() === currentDate.getMonth() &&
      date.getDate() === currentDate.getDate()
  );
  // console.log(maindata?._id, "ooooooooo");
  const params = {
    doctorID:
      maindata?.isSelected?.data._id ||
      maindata?.maindata?._id ||
      RescheduleData?.doctor_id?._id,
    date: moment(selectedDate).format("YYYY-MM-DD").toString(),
    userTimeZone:
      maindata?.isSelected?.data?.basicInfo?.userTimeZone ||
      maindata?.maindata?.basicInfo?.userTimeZone ||
      "America/New_York",
  };
  const { response, getdata, isloading } = Fetchdata(
    AppoinmentCalenderApi,
    params,
    "POST"
  );

  useEffect(() => {
    getdata();
  }, [selectedDate]);

  const ResedulApi = async () => {
    setloading(true);
    try {
      const res = await axios.post(
        reschedulebookappointmentApi,
        {
          date: moment(selectedDate).format("YYYY-MM-DD").toString(),
          time: getObjectTime,
          appointment_id: RescheduleData._id,
          session: RescheduleData?.session,
          utc_time: formattedTime,
        },
        header
      );
      if (res.status === 200 && res.data.status === "success") {
        toast.success(res.data.message);
      } else if (res.status === 200 && res.data.status === "failed") {
        toast.error(res.data.message);
      }
      setloading(false);
      // console.log(res, "Submit res........");
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  const [blockedSlots, setBlockedSlots] = useState([]);

  const selectedProfileId = JSON.parse(
    localStorage.getItem("selectedProfileId")
  );

  // console.log(selectedProfileId, "selectedProfileId");
  useEffect(() => {
    setShowVirtualTimes(true);
  }, []);

  // useEffect(() => {}, []);

  // const handleDayClick = (day) => {
  //   setSelectedDays((prevDays) =>
  //     prevDays.includes(day)
  //       ? prevDays.filter((d) => d !== day)
  //       : [...prevDays, day]
  //   );
  // };
  const handleTimeClick = (time, slotType) => {
    if (showVirtualTimes) {
      setVirtualTimes((prevTimes) =>
        prevTimes.includes(time) ? prevTimes.filter((t) => t !== time) : [time]
      );
    }

    if (showInPersonTimes) {
      setInPersonTimes((prevTimes) =>
        prevTimes.includes(time) ? prevTimes.filter((t) => t !== time) : [time]
      );
    }

    // Create a moment object for the selected time
    const selectedDateTime = moment(
      `${moment(selectedDate).format("YYYY-MM-DD")} ${time}`,
      "YYYY-MM-DD h:mm A"
    );

    // Check if the selected time is after the current time
    const isSlotAfterCurrentTime = selectedDateTime.isAfter(moment());

    // Update selectedTimes only if the selected time is after the current time
    setSelectedTimes((prevTimes) => (isSlotAfterCurrentTime ? [time] : []));

    setslotetype(slotType);
    if (slotType === "virtual") {
      // Additional logic for virtual slot type if needed
    } else if (slotType === "inperson") {
      // Additional logic for in-person slot type if needed
    } else if (slotType === "block") {
      // Additional logic for block slot type if needed
    }
  };

  // const toggleButton = () => {
  //   setIsToggled((prevIsToggled) => (prevIsToggled ? 0 : 1));
  // };

  const handleSessionChange = (session) => {
    // Toggle the selected session
    setSelectedSession((prevSession) =>
      prevSession === session ? "" : session
    );
  };
  useEffect(() => {
    const currentTime = new Date().toLocaleTimeString("en-US", {
      hour12: false,
      hour: "numeric",
      minute: "numeric",
    });

    // Filter out slots that have already passed
    const filteredSlots = response?.data?.timeSlots
      .find((sessionData) => sessionData.session === selectedSession)
      .slots.filter((timeSlot) => timeSlot.slotTime >= currentTime);

    // Update state with the filtered slots
    setBlockedSlots(filteredSlots);
  }, []);

  const header = Headers();

  // console.log(response);

  // const paymentData = {
  //   email, // User email
  //   amount, // Amount in kobo (1000 kobo = 10 NGN)
  //   publicKey, // Public key for Paystack
  // };

  let dataAppo = moment(selectedDate).format("YYYY-MM-DD");
  let timeAppo = getObjectTime;
  let dateString = `${dataAppo} ${timeAppo}`;
  let dateFormat = "YYYY-MM-DD h:mm A";
  let dateObject = moment(dateString, dateFormat);
  let timestampMilliseconds = dateObject.valueOf();

  const onSuccess = async (ref) => {
    // console.log(ref);
    setloading(true);
    const selectedProfileId = localStorage.getItem("selectedProfileId");
    try {
      const res = await axios.post(
        Pymentbookappointment,
        {
          hospital_id: "",
          date: moment(selectedDate).format("YYYY-MM-DD").toString(),
          time_slot: getObjectTime,
          doctor_id: OkayData?.isSelected?.data?._id,
          appointmentType: slotetype,
          reasonAppointment: Consultation,
          familyMemberId:
            selectedProfileId?.relationship === "self"
              ? ""
              : selectedProfileId?._id,
          session: selectedSession,
          appointment_duration: "15 minutes",
          reference: ref.reference,
          choose_payment_method: "private_payment",
          // symptoms: OkayDataApi?.selectedsymtom,
          // healthCard_id: "",
          // stripe_card_id: selectedcard?.cardid,
          // consultation_fee: currencyAmmunt?.amount,
          // payment_currency: currencyAmmunt?.currency,
          // insurance_id: "",
          utc_time: timestampMilliseconds.toString(),
        },
        header
      );
      setloading(false);
      if (
        res.status === 200 &&
        res.data.message === "Appointment Booked successfully"
      ) {
        store.dispatch(setSessionField(NUMBER, undefined));
        toast.success(res.data.message);
        navigate("/Pymentsuccess");
        setFindMDFemaliy("");
      } else {
        toast.error(res.data.message);
        // console.log("elsepart");
      }
      // console.log(res, "Apicall res.........");
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };
  const onClose = (ref) => {
    // console.log(ref);
  };

  const Updata = () => {
    return (
      <>
        {PathNamere === "/UpcomingAppointmentCount" ? (
          ""
        ) : (
          <div className="slote-card-head mt-4">
            <div>
              <img
                className="Cardimg-docter"
                src={
                  maindataitm?.basicInfo?.image ||
                  maindata?.maindata?.basicInfo?.image
                }
                alt=""
              />
              <div></div>
            </div>
            <div className="Dorothy-Lucas-sapn-div">
              <div className="Height-awy-main-div">
                <div className="Dorothy-extra-div">
                  <span
                    className="Dorothy-sapn"
                    style={{
                      color: "white",
                      fontSize: "20px",
                      fontWeight: "700",
                    }}
                  >
                    {maindataitm?.basicInfo?.fullName ||
                      maindata?.maindata?.basicInfo?.fullName}
                  </span>
                </div>
                <div className="Height-w-bmi-div mt-2">
                  <div className="Height-Weight-main-div">
                    <span>Height</span>
                    <span>
                      {selectedProfileId?.healthData?.height?.value}{" "}
                      {selectedProfileId?.healthData?.height?.unit}
                    </span>
                  </div>
                  <div className="Height-Weight-main-div">
                    <span>Weight</span>
                    {selectedProfileId?.healthData?.weight?.value}{" "}
                    {selectedProfileId?.healthData?.weight?.unit}
                  </div>
                  <div className="Height-Weight-main-div">
                    <span>BMI</span>
                    <span>
                      {" "}
                      {parseInt(selectedProfileId?.healthData?.BMI).toFixed(2)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <Header
        onClick={() => navigate(-1)}
        text={
          PathNamere === "/UpcomingAppointmentCount"
            ? "Reshedule appointment"
            : "Appointment detail"
        }
        style={{ color: "white" }}
        extrafield={<Updata />}
      />
      <div>
        <div className="Calender_main_divv">
          <div className="Calender_Month_secondary_divv">
            <div className="July_main_divv">
              <div className="July_main_tagg_divv">
                {/* <button className="Arrow_btnn_left" onClick={handlePrevMonth}>
                  {Icons.leftarrow}
                </button> */}
                <div className="MAin_claender_divv">
                  <span className="July_main_tagg_text_divv">
                    {currentMonth.toLocaleString("default", {
                      month: "long",
                    })}{" "}
                    {currentMonth.getFullYear()}
                  </span>
                </div>
                {/* <button className="Arrow_btnn_left" onClick={handleNextMonth}>
                  {Icons.rightarrow}
                </button> */}
              </div>
              <div className="view_detail_main_divv">
                <div className="View_imgg_Full_month" onClick={handleClick}>
                  <div className="Feed_imgg_divv"></div>
                  <div
                    className="Feed_spann_divv"
                    onClick={() => navigate("/Viewmonthlymenu")}
                  >
                    <span className="Feed_span_text_divv"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Swiper_Calender_Month_secondary_divv">
            <div className="sliderdiv">
              <button
                className="left-swiper-btn mt-4"
                // onClick={() => btnref.current.swiper.slidePrev()}
                onClick={prevslide}
              >
                {Icons.previcon}
              </button>
              <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                slidesPerView={1}
                // centeredSlides={true} // Set centeredSlides to true
                initialSlide={currentDateIndex !== -1 ? currentDateIndex : 0}
                ref={btnref}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                breakpoints={{
                  1280: {
                    slidesPerView: 12,
                  },
                  540: {
                    slidesPerView: 6,
                  },
                  360: {
                    slidesPerView: 4,
                  },
                }}
                className="swiper-wrapper"
              >
                {/* <div className=""> */}
                {monthDates.map((date, index) => {
                  const currentDateWithoutTime = new Date();
                  currentDateWithoutTime.setHours(0, 0, 0, 0);
                  const isPastDate = date < currentDateWithoutTime;
                  const isSelected =
                    selectedDate && selectedDate.getTime() === date.getTime();
                  const isCurrentDate =
                    date.getTime() === currentDateWithoutTime.getTime();

                  return (
                    <SwiperSlide key={`${date.getTime()}-${index}`}>
                      <div
                        className={`Twent_july_spann_divv_swiper mt-4 ${
                          isPastDate ? "greyed-out" : ""
                        } ${isSelected ? "selected-date" : ""} ${
                          isCurrentDate ? "selected-date" : ""
                        }`}
                        onClick={() => handleDateClick(date)}
                      >
                        <div className="twenty_spann_divv">
                          <span className="twenty_spann_span">
                            {/* {date.getDay() + 1} */}
                            {date.toLocaleString("default", {
                              day: "2-digit",
                            })}
                          </span>
                        </div>
                        <div className="JULY_spann_divv">
                          <span className="July_spann_span">
                            {date.toLocaleString("default", {
                              weekday: "short",
                            })}
                          </span>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
                {/* </div> */}
              </Swiper>
              <button
                className="left-swiper-btn mt-4"
                // onClick={() => btnref.current.swiper.slideNext()}
                onClick={nextslide}
              >
                {Icons.nexticon}
              </button>
            </div>
          </div>
        </div>
      </div>
      <section className="BookAnAppointment1-main-section">
        <div className="BookAnAppointment1-main-div">
          {loading && <Loader />}
          <div className="mt-4 appointment-main-div-appointment">
            <span className="virtual-cls">
              Only virtual appointment for the same day
            </span>
          </div>
          <div className="Block-Virtual-Person">
            <div className="Block-span-div">
              <div className="div-Block"></div>
              <span>Block</span>
            </div>
            <div className="Block-span-div">
              <div className="div-Virtual"></div>
              <span>Virtual</span>
            </div>
            <div className="Block-span-div">
              <div className="div-Person"></div>
              <span>In Person</span>
            </div>
          </div>
          {selectedSession && (
            <>
              {isloading ? (
                <SimpleLoader style={{ height: "300px" }} />
              ) : (
                <>
                  {!response?.data ? (
                    <>
                      <h1 className="mt-5 mb-3">{response?.message}</h1>
                    </>
                  ) : (
                    <>
                      <div className="Appointment-main-div-Time">
                        <span>Select Appointment Time</span>
                        <select
                          className="Enter-Dose-value-Route mt-2"
                          name="Dose"
                          id="Dose"
                          onChange={(e) => handleSessionChange(e.target.value)}
                          value={selectedSession}
                        >
                          <option value="" disabled>
                            Select session
                          </option>
                          <option value="morning">morning</option>
                          <option value="afternoon">afternoon</option>
                          <option value="evening">evening</option>
                        </select>
                      </div>
                      <div className="time-slots mt-5 mb-5">
                        {response?.data?.timeSlots &&
                          response.data.timeSlots
                            .find(
                              (sessionData) =>
                                sessionData.session === selectedSession
                            )
                            ?.slots?.map((timeSlot) => {
                              const slotDateTime = moment(
                                timeSlot.slotTime,
                                "h:mm A"
                              );
                              const isSameDay = slotDateTime.isSame(
                                selectedDate,
                                "day"
                              );
                              const isSlotBeforeCurrentTime =
                                isSameDay && slotDateTime.isBefore(currentTime);
                              const isSlotAfterCurrentTime =
                                isSameDay && slotDateTime.isAfter(currentTime);

                              return (
                                <button
                                  key={timeSlot._id}
                                  className={`days-button ${
                                    selectedTimes.includes(timeSlot.slotTime)
                                      ? "active"
                                      : ""
                                  } ${
                                    timeSlot.slotType === "inperson"
                                      ? "inperson-bg"
                                      : ""
                                  } ${
                                    timeSlot.slotType === "virtual"
                                      ? "virtual-bg"
                                      : ""
                                  }
                        ${timeSlot.isBooked === true ? "gray-bg" : ""}
                        ${
                          timeSlot.slotType === "block" && timeSlot.isBooked
                            ? "disabled gray-bg"
                            : isSlotBeforeCurrentTime
                            ? "gray-bg"
                            : ""
                        } ${isSlotAfterCurrentTime ? "selectable" : ""}`}
                                  onClick={() => {
                                    const today = moment();
                                    const isAfter =
                                      moment(selectedDate).isAfter(today);
                                    const isSameDay = moment().isSame(
                                      selectedDate,
                                      "day"
                                    );
                                    const isSlotBeforeCurrentTime =
                                      isSameDay &&
                                      moment(
                                        timeSlot.slotTime,
                                        "h:mm A"
                                      ).isBefore(currentTime);

                                    !isSlotBeforeCurrentTime &&
                                      !timeSlot.isBooked &&
                                      timeSlot.slotType !== "block" &&
                                      handleTimeClick(
                                        timeSlot.slotTime,
                                        timeSlot.slotType
                                      );
                                  }}
                                  disabled={
                                    timeSlot.isBooked || isSlotBeforeCurrentTime
                                  }
                                >
                                  {timeSlot.slotTime}
                                </button>
                              );
                            })}
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
          {PathNamere === "/UpcomingAppointmentCount" ? (
            <button
              className="Done-mbtn-with-pop mt-3 mb-3"
              onClick={ResedulApi}
            >
              Reshedule
            </button>
          ) : (
            <PaystackButton
              className="Done-mbtn-with-pop mt-3 mb-3"
              disabled={isloading || selectedTimes.length < 1 ? true : false}
              email={userDATA?.basicInfo?.email}
              amount={+response?.data?.consultation_fee?.amount * 100}
              // currency={response?.data?.consultation_fee?.currency}
              publicKey={process.env.REACT_APP_PAY_KEY}
              text="Pay Now"
              onSuccess={onSuccess}
              onClose={onClose}
            />
          )}
        </div>
      </section>
    </>
  );
};

export default BookandAppoinmentSlote;

{
  /* <button
              className="Done-mbtn-with-pop mt-3 mb-3"
              onClick={handlenext}
            >
              Next
            </button> */
}
