import React, { useState, useContext, useEffect } from "react";
import "./CreateProfileDoctor.css";
import { useFormik } from "formik";
import { CreateProfileDoctorSchemas } from "../../Schemas/index";
import profilePic from "../../Assets/Images/default_pic_ic@3x.png";
import SandIcon from "../../Assets/Images/camera_ic_yellow@3x.png";
import Blue from "../../Assets/Images/blue_bg@3x.png";
import { GlobalContext } from "../../Context/GlabalContext";
import Fax from "../../Assets/Images/fax_ic@3x.png";
import { useNavigate } from "react-router-dom";
import { Icons } from "../../Constant/Icons";
import { toast } from "react-toastify";
import moment from "moment";

const CreateProfileDoctor = () => {
  const [Image, setImage] = useState(null);
  const navigate = useNavigate();
  const { steps, setsteps, setDoctorRegisterdata } = useContext(GlobalContext);
  const [selected, setselected] = useState("");

  // initialValues........................
  const initialValues = {
    FullName: "",
    LastName: "",
    image: "",
    date: "",
    gender: "",
    Mobile: "",
    FaxNumber: "",
  };

  // Calculateagefunction........................
  const calculateage = (dob) => {
    let today = new Date();
    let birthdatobj = new Date(dob);
    let age = today.getFullYear() - birthdatobj.getFullYear();
    const month = today.getMonth() - birthdatobj.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthdatobj.getDate())) {
      age--;
    }
    return age;
  };
  // doctor register function........................
  const onSubmit = () => {
    let age = calculateage(values.date);
    if (age < 18 || age > 65) {
      toast.error("Age must be 18 +");
    } else {
      const data = {
        FullName: values.FullName,
        LastName: values.LastName,
        date: BodRegister,
        image: Image,
        age: age,
        gender: values.gender,
        Mobile: values.Mobile,
        FaxNumber: values.FaxNumber,
      };
      setDoctorRegisterdata((prev) => ({ ...prev, ...data }));
      setsteps(3);
    }
  };

  // formik..............................
  const {
    values,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: CreateProfileDoctorSchemas,
    onSubmit,
  });

  const BodRegister = moment(values.date).format("DD-MM-YYYY");

  return (
    <>
      <div className="w-100">
        <div className="All-Containor-perfect-second-divv">
          <div className="Profile-extra-div">
            <div className="Profile-main-Your">
              <span onClick={() => setsteps(1)}>{Icons.backarrowblack}</span>
              <h6 className="mt-2">Step {steps} to 3</h6>
              <span className="Order-history-span">Create Profile</span>
              <span className="Upload-profile">
                Enter your information below to create profile.
              </span>
            </div>
          </div>
          <form onSubmit={handleSubmit} className="second-div">
            <div className="all-input-CreateProfileDoctor-div-main">
              <div className="Ht-ml-for-imput">
                <label className="" htmlFor="mal">
                  <img src={profilePic} alt="" className="pic-on-add" />
                  <div className="SandIcon-addeting-div">
                    <img className="Cemara-te-img" src={SandIcon} alt="" />
                  </div>
                </label>
                <input
                  id="mal"
                  type="file"
                  name="image"
                  onChange={(e) => {
                    setImage(e.target.files[0]);
                    setFieldValue(
                      "image",
                      URL.createObjectURL(e.target.files[0])
                    );
                  }}
                  className="Malte-pal-cls"
                  style={{ display: "none" }}
                />
                <img
                  src={Image ? URL.createObjectURL(Image) : null}
                  alt=""
                  width="100px"
                  height="100px"
                  className="add-kiya-muja"
                />
              </div>
              {errors.image && touched.image ? (
                <p className="mastu-to-eroor-FullName mt-3">{errors.image} </p>
              ) : null}
              <div className="mt-5 all-input-areya">
                <div className="full-and-last-name-creat">
                  <div className="Full-all-Name-input-bug">
                    <span className="">Full name</span>
                    <div className="full-name-bug-div">
                      <div className="text-CreateProfileDoctor-fullname mt-2">
                        <span>{Icons.usericon}</span>
                        <input
                          className="i-t-a-move-host-itm"
                          type="text"
                          placeholder="Full name"
                          name="FullName"
                          value={values.FullName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    {errors.FullName && touched.FullName ? (
                      <p className="mastu-to-eroor-FullName mt-2">
                        {errors.FullName}{" "}
                      </p>
                    ) : null}
                  </div>
                  <div>
                    <span className="">Last name</span>
                    <div className="full-name-bug-div">
                      <div className="text-CreateProfileDoctor-fullname mt-2">
                        <span>{Icons.usericon}</span>
                        <input
                          className="i-t-a-move-host-itm"
                          type="text"
                          placeholder="Last name"
                          name="LastName"
                          value={values.LastName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    {errors.LastName && touched.LastName ? (
                      <p className="mastu-to-eroor-FullName mt-2">
                        {errors.LastName}{" "}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="Gender-Male-Female-div mt-4">
                  <span>Gender</span>
                  <div className="Gender-main-div mt-2">
                    <button
                      className={
                        selected == "male"
                          ? "Female-btn-mainactive "
                          : "Female-btn-main"
                      }
                      type="button"
                      onClick={() => {
                        setFieldValue("gender", "male");
                        setselected("male");
                      }}
                    >
                      Male
                    </button>
                    <button
                      className={
                        selected == "female"
                          ? "Female-btn-mainactive "
                          : "Female-btn-main"
                      }
                      type="button"
                      onClick={() => {
                        setFieldValue("gender", "female");
                        setselected("female");
                      }}
                    >
                      Female
                    </button>
                  </div>
                  {errors.gender && touched.gender ? (
                    <p className="mastu-to-eroor">{errors.gender}</p>
                  ) : null}
                </div>
                <div className="input-date-main-div mt-4">
                  <span>Date of Birth</span>
                  <input
                    placeholder="13 May 1995"
                    className="Date-of-Birth-input-physician"
                    type="date"
                    max="9999-12-31"
                    name="date"
                    value={values.date}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {errors.date && touched.date ? (
                    <p className="mastu-to-eroor">{errors.date} </p>
                  ) : null}
                </div>
                <div className="mt-2">
                  <span className="">Mobile</span>
                  <div className="full-name-bug-div">
                    <div className="text-Phone-fullname mt-2">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_12_1283)">
                          <path
                            d="M7 4V20H17V4H7ZM6 2H18C18.2652 2 18.5196 2.10536 18.7071 2.29289C18.8946 2.48043 19 2.73478 19 3V21C19 21.2652 18.8946 21.5196 18.7071 21.7071C18.5196 21.8946 18.2652 22 18 22H6C5.73478 22 5.48043 21.8946 5.29289 21.7071C5.10536 21.5196 5 21.2652 5 21V3C5 2.73478 5.10536 2.48043 5.29289 2.29289C5.48043 2.10536 5.73478 2 6 2ZM12 17C12.2652 17 12.5196 17.1054 12.7071 17.2929C12.8946 17.4804 13 17.7348 13 18C13 18.2652 12.8946 18.5196 12.7071 18.7071C12.5196 18.8946 12.2652 19 12 19C11.7348 19 11.4804 18.8946 11.2929 18.7071C11.1054 18.5196 11 18.2652 11 18C11 17.7348 11.1054 17.4804 11.2929 17.2929C11.4804 17.1054 11.7348 17 12 17Z"
                            fill="black"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_12_1283">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <input
                        className="i-t-a-Phone-itm"
                        type="number"
                        placeholder="Mobile"
                        name="Mobile"
                        value={values.Mobile}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {errors.Mobile && touched.Mobile ? (
                    <p className="mastu-to-eroor">{errors.Mobile} </p>
                  ) : null}
                </div>
                <div className="mt-2">
                  <span className="">Fax Number</span>
                  <div className="full-name-bug-div">
                    <div className="text-Phone-fullname mt-2">
                      <img className="Fax-img-main" src={Fax} alt="" />

                      <input
                        className="i-t-a-Phone-itm"
                        type="number"
                        placeholder="Fax Number"
                        name="FaxNumber"
                        value={values.FaxNumber}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {errors.FaxNumber && touched.FaxNumber ? (
                    <p className="mastu-to-eroor">{errors.FaxNumber} </p>
                  ) : null}
                </div>
              </div>
              <div className="mt-5 mb-5">
                <button
                  type="submit"
                  className="Btn-Register-karo-CreateProfileDoctor"
                  onClick={() => setsteps(2)}
                >
                  Next
                </button>
              </div>
            </div>
          </form>
          <div>
            <img className="CreateProfileDoctor-next-blue" src={Blue} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateProfileDoctor;
