import React, { useContext, useEffect, useRef, useState } from "react";
import "./FindMD.css";
import Cardimg from "../../Assets/Images/ForgotPasswordImg.avif";
import time from "../../Assets/Images/time_ic@3x.png";
import location from "../../Assets/Images/location_ic_sm@3x.png";
import Header from "../../Components/Header/Header";
import Input from "../../Components/Input/Input";
import { Icons } from "../../Constant/Icons";

import { useNavigate } from "react-router-dom";
import Fetchdata from "../../Constant/Fetchdata";
import { DOCTORLIST } from "../../Constant/Apiconstant";
import { SimpleLoader } from "../../Constant/Loader";
import { GlobalContext } from "../../Context/GlabalContext";
const FindMD = () => {
  const navigate = useNavigate();
  const [docterlist, setdocterlist] = useState([]);
  const [keyword, setkeyword] = useState("");
  const [pagecount, setpagecount] = useState(1);
  const { FindMDFemaliy, setFindMDFemaliy } = useContext(GlobalContext);
  // console.log(FindMDFemaliy, "FindMDFemaliy");
  const params = {
    key: keyword,
    page: pagecount,
  };
  const { response, isloading, getdata } = Fetchdata(
    DOCTORLIST,
    params,
    "POST"
  );
  // console.log(response, "response");
  const pagenumber = useRef(1);
  const totalpage = useRef(0);

  //   totalpage.current = response?.data?.totalPages;
  //   if (keyword === "") {
  //     if (Array.isArray(response?.data?.docs)) {
  //       setdocterlist((prev) => {
  //         let prevArray = Array.isArray(prev) ? prev : [];
  //         let data = response?.data?.docs
  //           ?.map((item) =>
  //             prevArray?.map((i) => i?.id).includes(item.id) ? false : item
  //           )
  //           .filter((item) => item);
  //         return [...new Set([...prevArray, ...data])];
  //       });
  //     }
  //   } else if (keyword !== "") {
  //     setdocterlist(response?.data?.docs);
  //   }
  // }, [response, keyword]);
  const handlescroll = () => {
    try {
      if (!isloading && totalpage.current != pagenumber.current) {
        if (response) {
          if (
            window.innerHeight + document.documentElement.scrollTop + 1 >=
            document.documentElement.scrollHeight
          ) {
            pagenumber.current = pagenumber.current + 1;
            setpagecount((prev) => prev + 1);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setkeyword("");
    window.addEventListener("scroll", handlescroll);
    return () => {
      window.removeEventListener("scroll", handlescroll);
    };
  }, []);

  useEffect(() => {
    getdata();
  }, [pagecount]);

  const debouncedelay = 500;
  useEffect(() => {
    let timer;
    if (keyword) {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        getdata();
      }, debouncedelay);
    } else if (keyword == "") {
      getdata();
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [keyword]);

  const handleclick = (item) => {
    setFindMDFemaliy(item._id);
    // console.log(item, "id ka liya");
    navigate("/DoctorProfile", {
      state: {
        item,
      },
    });
  };

  return (
    <>
      <Header
        onClick={() => navigate(-1)}
        text={"Find MD"}
        extrafield={
          <Input
            placeholder={"Search"}
            onChange={(e) => setkeyword(e.target.value)}
            style={{ backgroundColor: "#ffffff" }}
            topicon={Icons.search}
          />
        }
        style={{ gap: "10px" }}
      />
      <section className="FindMD-main-section">
        {isloading ? (
          <SimpleLoader style={{ height: "300px" }} />
        ) : (
          <div className="FindMD-main-div">
            {docterlist ? (
              <>
                {response?.data?.docs?.map((itm, index) => {
                  {
                    /* console.log(itm); */
                  }
                  return (
                    <div
                      key={index}
                      className="FindMD-img-contain"
                      onClick={() => handleclick(itm)}
                    >
                      <div>
                        <img
                          className="Cardimg-docter"
                          src={itm.basicInfo.image}
                          alt=""
                        />
                      </div>
                      <div className="Dorothy-Lucas-sapn-div">
                        <div className="Lucas-awy-main-div">
                          <div className="Dorothy-extra-div">
                            <span className="Dorothy-sapn">
                              Dr.
                              {itm.basicInfo.fullName}
                            </span>
                            <span className="General-span">{itm.digry}</span>
                          </div>
                          {/* <div className="Awy-div">
                        <span className="Awy-btn-span">Awy</span>
                      </div> */}
                        </div>
                        <div className="mt-2">
                          {itm?.consultationSchedule?.sessions?.length > 0 && (
                            <div>
                              <img className="time-img-css" src={time} alt="" />
                              <span className="Am-pm-span">
                                {itm.consultationSchedule.sessions[0].slotTime}
                              </span>{" "}
                              to{" "}
                              {itm.consultationSchedule.sessions.length > 1 && (
                                <span className="Am-pm-span">
                                  {
                                    itm.consultationSchedule.sessions.slice(
                                      -1
                                    )[0].slotTime
                                  }
                                </span>
                              )}
                            </div>
                          )}
                        </div>
                        <div className="">
                          <img className="time-img-css" src={location} alt="" />
                          <span className="Am-pm-span">
                            {" "}
                            {itm.basicInfo.address.length > 40
                              ? itm.basicInfo.address.substring(0, 40) + "..."
                              : itm.basicInfo.address}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <h1 style={{ textAlign: "center" }}>{response?.message}</h1>
            )}
          </div>
        )}
      </section>
    </>
  );
};

export default FindMD;
