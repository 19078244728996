import React, { useContext, useEffect, useRef, useState } from "react";
import Video from "twilio-video";
import Header from "../../Components/Header/Header";
import Fetchdata, { Headers } from "../../Constant/Fetchdata";
import {
  WaitingRoomjoinigAPI,
  StopTimeSet,
  StopTimeGet,
} from "../../Constant/Apiconstant";
import axios from "../../Constant/ApiUrl";
import { Icons } from "../../Constant/Icons";
import { useLocation, useNavigate } from "react-router-dom";
import Countdwon from "../../Assets/Images/countdown_blue_ic@3x.png";
import { FiCameraOff } from "react-icons/fi";
import { IoMdMicOff } from "react-icons/io";
import DataSuccessfully from "../../Doctor/DataSuccessfully/DataSuccessfully";
import { GlobalContext } from "../../Context/GlabalContext";

const PatientVidoCall = () => {
  const [room, setRoom] = useState(null);

  const [token, setToken] = useState("");

  const [isCameraOn, setIsCameraOn] = useState(true);
  const [isMicrophoneOn, setIsMicrophoneOn] = useState(true);
  const [Popup, setPopup] = useState(false);
  const [isCameraEnabled, setIsCameraEnabled] = useState(true);
  const [isMicrophoneEnabled, setIsMicrophoneEnabled] = useState(true);

  const [minuteFromApi, setMinuteFromApi] = useState(15);

  // console.log(minuteFromApi, "minuteFromApi");
  const initialTime = minuteFromApi * 60 * 1000; // 15 minutes in milliseconds
  const [timeRemaining, setTimeRemaining] = useState(initialTime);
  const [timerStarted, setTimerStarted] = useState(false);
  const { PatcutCall, setPatcutCall } = useContext(GlobalContext);

  const navigate = useNavigate();
  const route = useLocation();
  const data = route?.state?.response;

  // console.log(data, "data");
  const header = Headers();

  useEffect(() => {
    if (timerStarted) {
      const intervalId = setInterval(() => {
        setTimeRemaining((prevTime) => {
          const newTime = Math.max(0, prevTime - 1000);
          if (newTime === 0) {
            // Timer reached 0, cut the call
            handleCutCall();
          }
          return newTime;
        });
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [timerStarted]);
  const minutes = Math.floor(timeRemaining / (1000 * 60));
  const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

  const startTimer = () => {
    setTimerStarted(true);
  };

  const stopTimer = () => {
    setTimerStarted(false);
    setTimeRemaining(initialTime);
  };

  const StoTimeApi = async () => {
    try {
      const res = await axios.post(
        StopTimeSet,
        {
          appointmentID: data.data.waitingRoomId,
          videoStopTime: minutes,
        },
        header
      );
      // console.log(res);
    } catch (error) {
      console.log(error);
    }
  };
  const TimegetApi = async () => {
    try {
      const res = await axios.post(
        StopTimeGet,
        {
          appointmentID: data.data.waitingRoomId,
        },
        header
      );

      // console.log(res);

      if (res.data.data) {
        setTimeRemaining(res.data.data * 60 * 1000);
        setMinuteFromApi(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    TimegetApi();
  }, []);

  const params = {
    waitingRoomId: data?.data?.waitingRoomId,
    patientId: data?.data?.patientId,
  };
  const { response: Joine } = Fetchdata(WaitingRoomjoinigAPI, params, "POST");

  useEffect(() => {
    // console.log("Joine response:", Joine);
    setToken(Joine?.data?.accessToken);
  }, [Joine]);
  // console.log(Joine, "Joine");
  const localVideoRef = useRef();
  const remoteVideoRef = useRef();

  useEffect(() => {
    if (Joine?.data) {
      const initializeVideo = async () => {
        try {
          // console.log("Access Token:", Joine?.data?.accessToken);

          const room = await Video.connect(Joine?.data?.accessToken, {
            name: Joine?.data?.roomName,
          });
          setRoom(room);

          room.on("trackSubscribed", (track) => {
            // console.log("Participant connected:", track);
            if (!timerStarted) {
              startTimer();
            }
          });

          // Create and display local video track
          Video.createLocalAudioTrack().then((localAudioTrack) => {
            room.localParticipant.publishTrack(localAudioTrack);
          });

          Video.createLocalVideoTrack().then((track) => {
            const localVideo = localVideoRef.current;
            localVideo.appendChild(track.attach());
            room.localParticipant.publishTrack(track);
          });

          room.on("trackSubscribed", (track, publication, participant) => {
            console.log("track subscribed:", track);

            const remoteVideo = remoteVideoRef.current;
            remoteVideo.appendChild(track.attach());
          });

          room.on("participantConnected", (participant) => {
            console.log("participant connected:", participant);
            participant.tracks.forEach((publication) => {
              if (publication.isSubscribed) {
                const track = publication.track;
                if (track.kind === "video") {
                  const remoteVideo = remoteVideoRef.current;
                  remoteVideo.appendChild(track.attach());
                }
              }
            });
            participant.on("trackSubscribed", (track) => {
              console.log("Remote video track subscribed:", track);
              if (track.kind === "video") {
                const remoteVideo = remoteVideoRef.current;
                remoteVideo.appendChild(track.attach());
              }
            });
          });

          room.on("participantDisconnected", (participant) => {
            console.log("Participant disconnected:", participant);
            room.localParticipant.videoTracks.forEach((publication) => {
              const track = publication.track;
              if (track) {
                track.stop();
                track.detach();
              }
            });

            StoTimeApi();
            setPatcutCall(true);
            navigate("/waitingroom", { state: { data: data } });
            room.disconnect();
          });
        } catch (error) {
          console.log("Error in initializeVideo:", error);
        }
      };

      initializeVideo();
    }
  }, [Joine]);

  const handleCameraToggle = () => {
    if (isCameraEnabled) {
      room.localParticipant.videoTracks.forEach((publication) => {
        const track = publication.track;
        if (track) {
          track.stop();
          track.detach();
        }
      });
    } else {
      Video.createLocalVideoTrack().then((newTrack) => {
        const localVideo = localVideoRef.current;
        localVideo.appendChild(newTrack.attach());
        room.localParticipant.publishTrack(newTrack);
      });
    }
    setIsCameraEnabled(!isCameraEnabled);
  };

  const handleMicrophoneToggle = () => {
    if (isMicrophoneEnabled) {
      room.localParticipant.audioTracks.forEach((publication) => {
        const track = publication.track;
        if (track) {
          track.disable();
        }
      });
    } else {
      Video.createLocalAudioTrack().then((newTrack) => {
        room.localParticipant.publishTrack(newTrack);
      });
    }
    setIsMicrophoneEnabled(!isMicrophoneEnabled);
  };

  const handleCutCall = () => {
    // console.log("Disconnecting room...");
    if (room) {
      console.log(room, "room");
      room.localParticipant.tracks.forEach((publication) => {
        const track = publication.track;
        if (track) {
          track.stop();
          track.detach();
        }
      });

      StoTimeApi();
      setPatcutCall(true);
      navigate("/waitingroom", { state: { data: data } });
      room.disconnect();
      // console.log("Room disconnected successfully");
    }
  };

  return (
    <>
      <Header onClick={() => navigate(-1)} />
      <div className="videocallbtn-and-count">
        <span className="hours-main-minutes">
          <img className="Countdwon-img-sapn" src={Countdwon} alt="" />
          {`${minutes}m: ${seconds}s`}
        </span>

        <div className="videocallbtn">
          <span onClick={handleCameraToggle}>
            {!isCameraEnabled ? (
              <div className="maicro-FiCameraOff-main-div">
                <FiCameraOff className="FiCameraOff-main" />
              </div>
            ) : (
              Icons.cameraicon
            )}
          </span>
          <span className="icon" onClick={handleCutCall}>
            {Icons.cutcallicon}
          </span>
          <span onClick={handleMicrophoneToggle}>
            {isMicrophoneEnabled ? (
              Icons.micicon
            ) : (
              <div className="maicro-FiCameraOff-main-div">
                <IoMdMicOff className="IoMdMicOff-main" />
              </div>
            )}
          </span>
        </div>
      </div>
      <div className="video-container">
        <div className="pation-video-container" ref={localVideoRef}></div>
        <div
          className="local-video-container"
          style={{ height: `${window.innerHeight - 45}px` }}
          ref={remoteVideoRef}
        ></div>
        <div className="callername"></div>
      </div>
    </>
  );
};

export default PatientVidoCall;
