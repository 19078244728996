import React, { useContext, useEffect, useRef, useState } from "react";
import LOginImg from "../../Assets/Images/LoginDocter.jpg";
import Emailicon from "../../Assets/Images/emailicon.png";
import Passwordicon from "../../Assets/Images/password_ic@3x.png";
import LoginLogo from "../../Assets/Images/login_logo@3x.png";
import { useFormik } from "formik";
import { LoginSchemas } from "../../Schemas/index";
import Apple from "../../Assets/Images/apple_ic@3x.png";
import Google from "../../Assets/Images/google_ic@3x.png";
import Blue from "../../Assets/Images/blue_bg@3x.png";
import "./Login.css";
import Input from "../../Components/Input/Input";
import { Icons } from "../../Constant/Icons";
import { Link, useNavigate, useLocation } from "react-router-dom";

import Buttton from "../../Components/Button/Buttton";
import { Colors } from "../../Constant/Colors";
import { GlobalContext } from "../../Context/GlabalContext";
import axios from "../../Constant/ApiUrl";
import {
  DOCTERLOGIN,
  DOCTORRESENDOTP,
  PATIENTLOGIN,
  RESENDOTP,
  SOCIALLOGIN,
} from "../../Constant/Apiconstant";
import { store } from "../../Redux/store";
import { setSessionField } from "../../Redux/SessionAction";
import { IS_LOGGED_IN, KEY_USER_DATA } from "../../Redux/Appconstant";
import Loader from "../../Constant/Loader";
import { toast } from "react-toastify";
import { signInWithPopup } from "firebase/auth";
import { social, provider } from "../../Firebase.js";

const Login = () => {
  const route = useLocation();
  const HospetalId = route?.state?.Item;
  // console.log(HospetalId, "HospetalId");
  const { role } = useContext(GlobalContext);
  const [loading, setloading] = useState(false);
  const [timeZone, setTimeZone] = useState("");
  // console.log(timeZone, "timeZone");
  const navigate = useNavigate();

  const Device_Token = localStorage.getItem("FCM");
  // console.log(Device_Token, "Device_Token");
  useEffect(() => {
    if (role === "") {
      navigate("/");
    }
  }, [role]);

  // console.log(role);

  useEffect(() => {
    const getUserTimeZone = () => {
      try {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const timeZoneMapping = {
          "Asia/Calcutta": "Asia/Kolkata",
          // Add more mappings as needed
        };
        const formattedTimeZone = timeZoneMapping[timeZone] || timeZone;
        setTimeZone(formattedTimeZone);
        // setTimeZone(timeZone);
      } catch (error) {
        console.error("Error retrieving time zone:", error);
      }
    };
    getUserTimeZone();
  }, []);

  const initialValues = {
    email: "",
    password: "",
  };
  // console.log(role);

  const Resendotp = async () => {
    let url =
      role == "patient"
        ? RESENDOTP
        : role == "physician"
        ? DOCTORRESENDOTP
        : null;
    try {
      const res = await axios.post(url, { email: values.email });
      if (res.data.status == "success" && res.status == 200) {
        navigate("/Otp");
      }
      // console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async () => {
    setloading(true);
    let url =
      role == "patient"
        ? PATIENTLOGIN
        : role == "physician"
        ? DOCTERLOGIN
        : null;
    try {
      const res = await axios.post(
        url,
        {
          email: values.email,
          password: values.password,
          userTimeZone: timeZone,
          deviceType: "web",
          deviceToken: Device_Token,
          hospital_id: HospetalId ? HospetalId : "",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // console.log(res);
      setloading(false);
      if (res.status === 200 && res.data.status === "success") {
        store.dispatch(setSessionField(KEY_USER_DATA, res.data.data));
        if (res.data.data.basicInfo.isEmailVerified == false) {
          Resendotp();
        } else {
          store.dispatch(setSessionField(IS_LOGGED_IN, true));
          toast.success(res.data.message);
          if (res.data.data.basicInfo.role == "patient") {
            navigate("/home-page");
            localStorage.setItem("isloginmtd", JSON.stringify(true));
          } else if (res.data.data.basicInfo.role == "physician") {
            if (res.data.data.isCompleteProfile) {
              navigate("/Home");
              localStorage.setItem("isloginmtd", JSON.stringify(true));
            } else {
              localStorage.setItem("isloginmtd", JSON.stringify(false));
              navigate("/AddBankAccount");
            }
          }
        }
      } else if (res.data.status === "failed") {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      setloading(false);
      toast.error(error.response.data.message);
    }
  };

  // let arr = ["bello", "label", "roller"];
  // let arr2 = ["cool", "lock", "cook"];

  // const findelment = (a) => {
  //   a.forEach((ele) => {
  //     console.log(ele.split(""));
  //     ele.split("");
  //   });
  // };

  // findelment(arr);

  // Social login api.................
  const Sociallogin = async (params) => {
    setloading(true);
    try {
      const res = await axios.post(SOCIALLOGIN, params);
      // console.log(res);
      setloading(false);
      toast.success(res.data.message);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  // Social login api.................
  const handleclick = () => {
    signInWithPopup(social, provider)
      .then((dataa) => {
        // console.log(dataa);
        const params = {
          firstName: dataa._tokenResponse.firstName,
          lastName: dataa._tokenResponse.lastName,
          email: dataa._tokenResponse.email,
          role: "patient",
          password: "",
          gender: "",
          age: "",
          dob: "",
          phoneNumber: "",
          address: "",
          lat: "",
          lng: "",
          weight: "",
          weightUnit: "",
          height: "",
          heightUnit: "",
          BMI: "",
          pastMedicalCondition: "",
          medicalCondition: "",
          allergies: "",
          medication: "",
          smoking: "",
          alcohol: "",
          marijuana: "",
          deviceType: "web",
          deviceToken: Device_Token,
        };
        if (dataa.user.uid) {
          Sociallogin(params);
        }
        return dataa;
      })
      .catch((error) => {
        console.error("Error signing in:", error);
      });
  };

  const [close, setclose] = useState(true);
  const { values, handleBlur, handleChange, touched, handleSubmit, errors } =
    useFormik({
      initialValues: initialValues,
      validationSchema: LoginSchemas,
      onSubmit,
    });

  const handlenavigate = () => {
    if (role === "physician") {
      navigate("/physician-register", {
        state: {
          HospetalId,
        },
      });
    } else {
      navigate("/register", {
        state: {
          HospetalId,
        },
      });
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.onload = () => {
      videoRef.current.src = fileReader.result;
    };
    fileReader.readAsDataURL(file);
  };
  const videoRef = useRef(null);
  // const [thumbnailUrl, setThumbnailUrl] = useState(null);

  // const generateThumbnail = () => {
  //   const canvas = document.createElement("canvas");
  //   canvas.width = videoRef.current.videoWidth;
  //   canvas.height = videoRef.current.videoHeight;
  //   canvas
  //     .getContext("2d")
  //     .drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

  //   const url = canvas.toDataURL();
  //   setThumbnailUrl(url);
  // };

  return (
    <>
      {/* <div>
        <input type="file" accept="video/*" onChange={handleFileChange} />
        <video ref={videoRef} width="320" height="240" controls>
          <source src="your_video_url_here.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <button onClick={generateThumbnail}>Generate Thumbnail</button>
        {thumbnailUrl && <img src={thumbnailUrl} alt="Thumbnail" />}
      </div> */}
      <div className="Login-section-main-div">
        <span onClick={() => navigate("/")}>{Icons.backarrowblack}</span>
        <div className="Login-section-parent-div">
          <div className="login-img-img-div">
            <div className="dado-img-login-dono mb-2">
              <img src={LOginImg} alt="" className="login-img-img" />
            </div>
          </div>
          <form onSubmit={handleSubmit} className="Blue-main-div">
            <img className="Blue-img" src={Blue} alt="" />
            <div className="login-section-all-input-div">
              <div className="submit-div-he">
                <div>
                  <img className="LOgin-Logo" src={LoginLogo} alt="" />
                </div>
                <div>
                  <span className="login-span-first">Login</span>
                </div>
                <div className="w-100">
                  <span>Email</span>
                  <Input
                    topimg={Emailicon}
                    placeholder={"Email"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type={"email"}
                    name={"email"}
                    value={values.email}
                  />
                  {errors.email && touched.email ? (
                    <p className="mastu-to-eroor mt-2">{errors.email} </p>
                  ) : null}
                </div>
                <div className="w-100">
                  <span>Password</span>
                  <Input
                    topimg={Passwordicon}
                    placeholder={"Password"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name={"password"}
                    // value={values.password}
                    type={close ? "password" : "text"}
                    bottomicon={close ? Icons.eyeclose : Icons.eyeopen}
                    bottomiconClick={() => setclose(!close)}
                  />
                  {errors.password && touched.password ? (
                    <p className="mastu-to-eroor mt-2">{errors.password} </p>
                  ) : null}
                </div>
                <div className="chek-div-remember-me">
                  <Link to={"/ForgotPassword"} className="Forgot-Password-span">
                    Forgot password?
                  </Link>
                </div>
                <Buttton
                  type={"submit"}
                  style={{ backgroundColor: Colors.blue }}
                >
                  Login
                </Buttton>
                <div>
                  <span>OR Login with</span>
                </div>
                <div className="Apple-and-Google-div">
                  {/* <div className="Apple-img-span-div">
                    <img className="Apple-icon" src={Apple} alt="" />
                    <span>Apple</span>
                  </div> */}
                  <div className="Apple-img-span-div" onClick={handleclick}>
                    <img className="Apple-icon" src={Google} alt="" />
                    <span>Google</span>
                  </div>
                </div>
                <div className="margin-111-div">
                  <div className="dont-have-register-div ">
                    <span className="dont-have-register-span">
                      Don’t have an account?{" "}
                      {/* <Link
                        to={
                          role === "physician"
                            ? "/physician-register"
                            : "/register"
                        }
                        className="only-register-span"
                      >
                        Sign Up
                      </Link> */}
                      <span
                        className="only-register-span"
                        onClick={handlenavigate}
                      >
                        Sign Up
                      </span>
                      .
                    </span>
                  </div>{" "}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {loading && <Loader />}
    </>
  );
};

export default Login;
